import {BusinessSettings} from '@/interfaces/business/businessSettings';
import {deleteRequest, ENDPOINTS, get, post, put} from '@/api/mainApi';
import Vue from 'vue';
import {Snackbar} from '@/interfaces/presentation/snackbar';
import {AxiosError} from 'axios';
import {Business} from "@/interfaces/business/business";

export class SystemRepository {
    constructor(private vueInstance: Vue) {
    }

    public async updateUnmonitoredDepots(maxValue: number): Promise<Boolean> {
        try {
            const unmonitoredSnippet = "update-unmonitored-depots";
            const response = await put(`${ENDPOINTS.SYSTEM}/${ENDPOINTS.MONITORS}/${unmonitoredSnippet}?maxValue=` + maxValue, {});
            this.setSnackbar('Hämtade monitorer för depåer som saknar det', 'success');
            return response;
        } catch (error) {
            this.handleError(error, `Misslyckades med att uppdatera monitorer: ${error.data.msg || 'Okänt fel'}`);
            return Promise.reject(error);
        }
    }

  public async updatePendingDepots(maxValue: number): Promise<Boolean> {
    try {
      const pendingSnippet = "update-pending-depots";
      const response = await put(`${ENDPOINTS.SYSTEM}/${ENDPOINTS.MONITORS}/${pendingSnippet}?maxValue=` + maxValue, {});
      this.setSnackbar('Hämtade väntande monitorer', 'success');
      return response;
    } catch (error) {
      this.handleError(error, `Misslyckades med att uppdatera monitorer: ${error.data.msg || 'Okänt fel'}`);
      return Promise.reject(error);
    }
  }

  public async updateTrackedDepots(): Promise<Boolean> {
    try {
      const trackedSnippet = "update-tracked-depots";
      const response = await put(`${ENDPOINTS.SYSTEM}/${ENDPOINTS.MONITORS}/${trackedSnippet}`, {});
      this.setSnackbar('Uppdaterade spårade monitorer', 'success');
      return response;
    } catch (error) {
      this.handleError(error, `Misslyckades med att uppdatera monitorer: ${error.data.msg || 'Okänt fel'}`);
      return Promise.reject(error);
    }
  }

    private setSnackbar(message: string, color: 'error' | 'success') {
        const snackbar: Snackbar = {
            active: true,
            color: color,
            text: message,
            timeout: 10000,
            action: null,
        };
        this.vueInstance.$store.commit('setSnackbar', snackbar);
    }

    private handleError(error: AxiosError, errorMessage: string) {
        if (error.response?.status === 403) {
            this.setSnackbar('Du saknar rättigheter för att utföra den begärda handlingen', 'error');
        } else {
            this.setSnackbar(errorMessage, 'error');
        }
    }
}












































import Vue from 'vue';
import { TableOptions } from '@/interfaces/Vuetify';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { Role, RoleStr } from '@/enums/role';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { ENDPOINTS, get, put } from '@/api/mainApi';
import dayjs from 'dayjs';

export default Vue.extend({
  data() {
    return {
      loading: false,
      roleStr: RoleStr,
      actionDialog: false,
      selectedUserAccount: null as UserAccount | null,
      userAccounts: [] as Array<UserAccount>,
      footerProps: {
        'items-per-page-all-text': 'alla',
        'items-per-page-options': [50, 100, 150],
        'items-per-page-text': 'Rader per sida',
      },
      tableHeaders: [
        {
          text: 'Skapad',
          value: 'createdAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Förnamn',
          value: 'firstname',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Efternamn',
          value: 'surname',
          align: 'left',
          sortable: true,
        },
        {
          text: 'E-post',
          value: 'email',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Tel.',
          value: 'phone',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Roll',
          value: 'role',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Företag',
          value: 'businessName',
          align: 'left',
          sortable: true,
        },
      ],
      optionsProps: {
        sortDesc: [false],
        multiSort: false,
      } as TableOptions,
    };
  },
  filters: {
    roleStr(role, roleStr) {
      return roleStr[role];
    },
  },
  created() {
    this.getPendingUserAccounts();
  },
  methods: {
    async getPendingUserAccounts() {

      try {

        this.loading = true;

        let pendingUserAccounts = await get(ENDPOINTS.USER_ACCOUNTS + '/' + ENDPOINTS.LIST + '/' + ENDPOINTS.PENDING);

        if (pendingUserAccounts) {
          this.userAccounts = pendingUserAccounts;
          this.massageData();
        }
      } catch (e) {
        console.log(e);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Något gick fel. Kunde inte hämta användare att godkänna eller neka ' + e.data.msg,
          timeout: 8000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      } finally {
        this.loading = false;
      }
    },
    spliceAccount() {
      this.userAccounts.splice(this.userAccounts.findIndex(account => account.id === this.selectedUserAccount.id), 1);
    },
    async denyRequest() {

      try {

        this.loading = true;

        let rejectRes = await put(ENDPOINTS.USER_ACCOUNTS + '/' + this.selectedUserAccount.id + '/' + ENDPOINTS.REJECT, {});

        if (rejectRes) {

          this.actionDialog = false;

          let snackbar: Snackbar = {
            active: true,
            color: 'success',
            text: 'Förfrågan nekad',
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);
          this.spliceAccount();
        }
      } catch (e) {
        console.log(e);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Misslyckades med att neka förfrågan: ' + e.data.msg,
          timeout: 8000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      } finally {
        this.loading = false;
      }
    },
    async approveRequest() {

      try {

        this.loading = true;

        let approveRes = await put(ENDPOINTS.USER_ACCOUNTS + '/' + this.selectedUserAccount.id + '/' + ENDPOINTS.APPROVE, {});

        if (approveRes) {

          this.actionDialog = false;

          let snackbar: Snackbar = {
            active: true,
            color: 'success',
            text: 'Förfrågan godkänd, en aktiveringslänk har skickats till ' + this.selectedUserAccount.email,
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);
          this.spliceAccount();
        }
      } catch (e) {
        console.log(e);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Misslyckades med att godkänna kontot: ' + e.data.msg,
          timeout: 8000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      } finally {
        this.loading = false;
      }
    },
    selectRow(userAccount: UserAccount) {
      this.actionDialog = true;
      this.selectedUserAccount = userAccount;
    },
    dateTimeFormat(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    dateFormat(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    massageData() {
      this.userAccounts.forEach(u => {
        u.createdAt = this.dateTimeFormat(u.createdAt);
        u.businessName = u.business.name;
      });
    },
  },
});

export interface EventLogType {
    id: number,
    icon: string,
    color: string,
    type: Type
}

export enum Type {
    KEEPSAKE_ATTACHED_FILE_OPENED = "KEEPSAKE_ATTACHED_FILE_OPENED",
    KEEPSAKE_ADDED = "KEEPSAKE_ADDED",
    KEEPSAKE_EXTRADITED = "KEEPSAKE_EXTRADITED",
    DEPOT_SEARCHED = "DEPOT_SEARCHED",
    DEPOT_OPENED = "DEPOT_OPENED",
    DEPOT_MARKED_AS_DECEASED_BY_USER = "DEPOT_MARKED_AS_DECEASED_BY_USER",
    SPAR_SEARCH_HIT = "SPAR_SEARCH_HIT",
    SPAR_SEARCH_NOT_FOUND = "SPAR_SEARCH_NOT_FOUND",
    DEPOT_API_GET_REQUEST = "DEPOT_API_GET_REQUEST",
    DEPOT_API_PUT_REQUEST = "DEPOT_API_PUT_REQUEST",
    DEPOT_API_POST_REQUEST = "DEPOT_API_POST_REQUEST",
    HISTORICAL_LOG = "HISTORICAL_LOG",
}

export enum EventLogTypeStr {
    KEEPSAKE_ATTACHED_FILE_OPENED = "Bifogad fil öppnad",
    KEEPSAKE_ADDED = "Bevakning inlagd",
    KEEPSAKE_EXTRADITED = "Bevakning utlämnad",
    DEPOT_SEARCHED = "Sökning gjord på depå",
    DEPOT_OPENED = "Depå öppnad",
    DEPOT_MARKED_AS_DECEASED_BY_USER = "Depå markerad som avliden av användare",
    SPAR_SEARCH_HIT = "Depå hittad i SPAR-sökning",
    SPAR_SEARCH_NOT_FOUND = "Depå ej hittad i SPAR-sökning",
    DEPOT_API_GET_REQUEST = "GET anrop mot depå-api",
    DEPOT_API_PUT_REQUEST = "PUT anrop mot depå-api",
    DEPOT_API_POST_REQUEST = "POST anrop mot depå-api",
    HISTORICAL_LOG = "Tidigare loggning (annat system)",
}

export enum EventLogTypeValue {
  KEEPSAKE_ATTACHED_FILE_OPENED = 1,
  KEEPSAKE_ADDED = 2,
  KEEPSAKE_EXTRADITED = 3,
  DEPOT_SEARCHED = 4,
  DEPOT_OPENED = 5,
  DEPOT_MARKED_AS_DECEASED_BY_USER = 6,
  SPAR_SEARCH_HIT = 7,
  SPAR_SEARCH_NOT_FOUND = 8,
  DEPOT_API_GET_REQUEST = 9,
  DEPOT_API_PUT_REQUEST = 10,
  DEPOT_API_POST_REQUEST = 11,
  DEPOT_CREATED = 12,
  DEPOT_MARKED_AS_DECEASED_BY_SPAR = 13,
  DEPOT_KEEPSAKE_DELETED = 14,
  KEEPSAKE_MOVED_FROM_STORAGE_PLACE = 15,
  KEEPSAKE_MOVED_FROM_OFFICE = 16,
  LIFE_ARCHIVE_DELETED = 17,
  DEPOT_DELETED_BY_EXTERNAL_SOURCE = 18,
  KEEPSAKE_NOTE_READ_BY_USER = 19,
  HISTORICAL_LOG = 29,
}

export const EventLogTypeArr = [
    {value: Type.KEEPSAKE_ATTACHED_FILE_OPENED, text: EventLogTypeStr.KEEPSAKE_ATTACHED_FILE_OPENED},
    {value: Type.KEEPSAKE_ADDED, text: EventLogTypeStr.KEEPSAKE_ADDED},
    {value: Type.KEEPSAKE_EXTRADITED, text: EventLogTypeStr.KEEPSAKE_EXTRADITED},
    {value: Type.DEPOT_SEARCHED, text: EventLogTypeStr.DEPOT_SEARCHED},
    {value: Type.DEPOT_OPENED, text: EventLogTypeStr.DEPOT_OPENED},
    {value: Type.DEPOT_MARKED_AS_DECEASED_BY_USER, text: EventLogTypeStr.DEPOT_MARKED_AS_DECEASED_BY_USER},
    {value: Type.SPAR_SEARCH_HIT, text: EventLogTypeStr.SPAR_SEARCH_HIT},
    {value: Type.SPAR_SEARCH_NOT_FOUND, text: EventLogTypeStr.SPAR_SEARCH_NOT_FOUND},
    {value: Type.DEPOT_API_GET_REQUEST, text: EventLogTypeStr.DEPOT_API_GET_REQUEST},
    {value: Type.DEPOT_API_PUT_REQUEST, text: EventLogTypeStr.DEPOT_API_PUT_REQUEST},
    {value: Type.DEPOT_API_POST_REQUEST, text: EventLogTypeStr.DEPOT_API_POST_REQUEST},
    {value: Type.HISTORICAL_LOG, text: EventLogTypeStr.HISTORICAL_LOG},
]

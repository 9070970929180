export enum BankidSignatureType {
  EXTRADITION = "EXTRADITION",
  KEEPSAKE_DELETION = "KEEPSAKE_DELETION",
  MARKED_AS_DEAD = "MARKED_AS_DEAD",
  CREATING_NEW_KEEPSAKES = "CREATING_NEW_KEEPSAKES",
  PERSONAL_DATA_AGREEMENT = "PERSONAL_DATA_AGREEMENT",
}

export enum BankidSignatureTypeCode {
  EXTRADITION = 1,
  KEEPSAKE_DELETION = 2,
  MARKED_AS_DEAD = 3,
  CREATING_NEW_KEEPSAKES = 4,
  PERSONAL_DATA_AGREEMENT = 5,
}













































































































import Vue from "vue";
import {Keepsake} from "@/interfaces/keepsake/keepsake";
import {KeepsakeType} from "@/interfaces/keepsake/keepsakeType";
import {AttachedFileType} from "@/interfaces/keepsake/enums/attachedFileType";
import {StorageType} from "@/interfaces/keepsake/enums/storageType";
import {Depot} from "@/interfaces/depot/depot";
import {StoragePlace} from "@/interfaces/place/storagePlace";
import FileUploadComponent from "@/components/FileUploadComponent.vue";
import {FileData} from "@/interfaces/fileData";
import {ENDPOINTS, get} from "@/api/mainApi";
import {UserAccount} from "@/interfaces/useraccount/userAccount";

export default Vue.extend({
  components: {FileUploadComponent},
  props: {
    selectedKeepsake: Object as () => Keepsake,
    value: Boolean,
    depots: Array as () => Array<Depot>
  },
  data() {
    return {
      requiredRules: [
        v => !!v || 'Obligatoriskt fält',
      ],
      keepsakeTypes: [] as Array<KeepsakeType>,
      storagePlaces: [] as Array<StoragePlace>,
      depotRules: [v => v && v.length > 0 || "Du måste välja minst en depå"]
    }
  },
  mounted() {
    this.getKeepsakeTypes()
    this.getStoragePlaces()
  },
  computed: {
    active: {
      get(): Boolean {
        return this.value
      },
      set(value: Boolean) {
        this.$emit('update:value', value)
      }
    },
    keepsake: {
      get(): Keepsake {
        return this.selectedKeepsake
      },
      set(value: Keepsake) {
        this.$emit('update:selectedKeepsake', value)
      }
    },
    sortedKeepsakeTypes(): Array<KeepsakeType> {
      return this.keepsakeTypes.toSorted((a: KeepsakeType, b: KeepsakeType) => (a.sortOrder || 0) - (b.sortOrder || 0));
    },
    editing() {
      return this.keepsake && this.keepsake.index >= 0
    },
    title() {
      if (this.editing) {
        return "Redigerar bevakning"
      }
      return "Ny bevakning"
    },
    buttonText() {
      if (this.editing) {
        return "Spara ändringar"
      }
      return "Lägg till"
    },
    icon() {
      if (this.editing) {
        return "mdi-file-edit-outline"
      }
      return "mdi-file-plus-outline"
    },
    attachedFileAllowed() {
      return this.keepsake && this.keepsake.keepsakeType && this.keepsake.keepsakeType.attachedFileType !== AttachedFileType.NONE
    },
    notStored(): boolean {
      return this.keepsake && this.keepsake.keepsakeType && this.keepsake.keepsakeType.storageType === StorageType.NOT_STORED
    },
    attachedFileMandatory() {
      return this.keepsake && this.keepsake.keepsakeType && this.keepsake.keepsakeType.attachedFileType === AttachedFileType.MANDATORY
    },
    sharable() {
      return this.keepsake && this.keepsake.keepsakeType && this.keepsake.keepsakeType.sharable
    },
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
  },
  watch: {
    active(val: Boolean) {
      if (val) {
        // we also set depot to first value if there is only one
        if (this.depots.length === 1) {
          this.keepsake.depots = [this.depots[0]]
        }
        // if there is only one StoragePlace we set it as well
        if (this.storagePlaces.length == 1) {
          this.keepsake.storagePlace = this.storagePlaces[0];
        }
      }
    }
  },
  methods: {
    saved() {
      if (this.$refs.form.validate()) {
        this.$emit('saved', this.keepsake)
        this.closeDialog()
      }
    },
    deleted() {
      this.$emit('deleted', this.keepsake)
      this.closeDialog()
    },
    closeDialog() {
      this.active = false
    },
    async getKeepsakeTypes() {
      this.keepsakeTypes = await get(ENDPOINTS.ORGANIZATIONS + "/" + this.user.business.organization.id + "/" + ENDPOINTS.KEEPSAKE_TYPES, { includeUnselectable: false })
    },
    async getStoragePlaces() {
      this.storagePlaces = await get(ENDPOINTS.BUSINESSES + "/" + this.user.business.id + "/" + ENDPOINTS.STORAGE_PLACES)
    },
    typeChanged(val: KeepsakeType) {
      // current type options dictate how files are handled
      this.keepsake.attachedFile = val.attachedFileType != AttachedFileType.NONE
      if (!val.sharable) {
        this.keepsake.depots = [this.depots[0]]
      }
      if (val.attachedFileType === AttachedFileType.MANDATORY) {
        this.keepsake.attachedFile = true
      }
      if (this.notStored) {
        this.keepsake.storagePlace = null;
      }
      this.keepsake.description = val.name
    },
    fileUploaded(fileData: FileData) {
      this.keepsake.fileData = fileData
    },
    limitNonSharableDepot(e) { // if keepsake is not sharable only one selection should be valid
      if(!this.sharable && e.length > 1) {
        e.shift()
      }
    },
  },
})


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":_vm.selectables,"label":_vm.label,"multiple":_vm.multiple,"hide-details":"","prepend-icon":"mdi-filter-variant","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(index < 3)?_c('v-chip',_vm._g({attrs:{"label":"","outlined":"","small":""}},on),[_c('span',{staticClass:"caption truncate"},[_vm._v(_vm._s(item.text))])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])]),(index === 3)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selected.length - 3)+" till)")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-checkbox',{attrs:{"input-value":attrs.inputValue,"readonly":""}}),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }








































































import Vue from 'vue';
import selectableRoles from "@/helpers/roleHelper";
import SocialSecurityNumberInputComponent from "@/components/form/SocialSecurityNumberInputComponent.vue";
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { RoleInterface } from '@/enums/role';

export default Vue.extend({
  components: {SocialSecurityNumberInputComponent},
  props: {
    user: Object as () => UserAccount,
    requiredRules: Array,
    emailRules: Array,
    roles: Array as () => Array<RoleInterface>,
    stateUser: Object as () => UserAccount,
    readOnlySocialSecurityNumber: Boolean,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    getSelectableRoles() {
      return selectableRoles(this.stateUser, this.roles);
    },
  }
});

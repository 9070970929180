import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import '@/plugins/log';
import '@/assets/styles.css';
import store from "@/store";
import VueRouter from 'vue-router'
import router from "@/router";
import Root from "@/apps/Root.vue";
import filters from "../filters/filters";
import VueMask from 'v-mask';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMask);

// Global filters
Vue.filter('calendarDate', filters.calendarDate);

new Vue({
    router,
    vuetify,
    beforeCreate() { this.$store.commit('initialiseStore');},
    render: h => h(Root),
    store: store,
}).$mount('#app');

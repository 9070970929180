































































































































import Vue from "vue";
import {Office} from "@/interfaces/office/office";
import {Business} from "@/interfaces/business/business";
import {Dialog} from "@/interfaces/presentation/dialog";
import {BusinessSettingsRepository} from "@/repositories/businessSettingsRepository";

export default Vue.extend({
  components: {},
  props: {
    businessMoveStep: Number,
    editBusinessDialog: Boolean,
    businesses: Array as () => Array<Business>,
    selectedBusiness: Object as () => Business | null,
    offices: Array as () => Array<Office>
  },
  data() {
    return {
      dialogActive: false,
      businessSettingsRepository: new BusinessSettingsRepository(this),
      validBusinessForm: false,
      newBusiness: null as Business | null,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
    }
  },
  watch: {
    editBusinessDialog(value) {
      if (!value) {
        this.resetForm()
      }
    }
  },
  computed: {
    filteredBusinesses(): Array<Business> {
      return this.businesses ? this.businesses.filter(b => b.id != this.selectedBusiness.id) : [];
    },
    currentStep: {
      get() {
        return this.businessMoveStep;
      },
      set(val: boolean) {
        this.$emit("update:businessMoveStep", val);
      }
    }
  },
  methods: {
    hasOffices(selectedBusiness: Business | null): boolean {
      return (selectedBusiness && selectedBusiness.offices && selectedBusiness.offices.length > 0) ? true : false;
    },
    async submit() {
      const response = await this.businessSettingsRepository.moveBusinessInformation(this.newBusiness.id, this.selectedBusiness.id);
      if (response) {
        this.currentStep = this.currentStep + 1;
      }
    },
    resetForm(reloadBusinesses: boolean | null) {
      this.$refs.newBusinessForm.reset();
      this.$emit("close");
      this.currentStep = 1;

      if (reloadBusinesses) {
        this.$emit("closeDialog");
        this.$emit("setInitialBusiness");
      }
    },
    nextTab() {
      this.$refs.newBusinessForm.validate();

      if (this.currentStep === 1 && this.validBusinessForm) {
        this.currentStep = this.currentStep + 1;
      }
    },
    confirmMoveBusiness() {
      this.dialogActive = true;
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Godkänn flytt",
        title: "Bekräfta flytt",
        text: "Är du säker på att du vill flytta allt innehåll från " + this.selectedBusiness.name + " till " + this.newBusiness.name + "? " +
            "Allt innehåll kommer att flyttas som exempelvis kontor, " +
            "anställda, depåer, bevakningar och förvaringsplatser. OBSERVERA att det inte går att ångra flytten.",
        actionClick: () => {
          this.dialogActive = false;
          this.submit();
        },
        closeClick: () => {
          this.dialogActive = false;
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
  }
});

import Vue from 'vue'
import Router from 'vue-router'
import HomeView from "@/views/home/HomeView.vue";
import LoginView from "@/views/external/LoginView.vue";
import AdministrationView from "@/views/administration/AdministrationView.vue";
import MyAccountView from "@/views/administration/MyAccountView.vue";
import DepotDetailsView from "@/views/depot/DepotDetailsView.vue";
import NewKeepsakeView from "@/views/keepsake/NewKeepsakeView.vue";
import DepotReceiptView from "@/views/depot/DepotReceiptView.vue";
import GlobalSettingsView from "@/views/administration/GlobalSettingsView.vue";


Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },
  routes: [
    {
      path: '/hem',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/',
      redirect: '/hem',
    },
    {
      path: '/inloggning',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/administration',
      name: 'administration',
      component: AdministrationView,
    },
    {
      path: '/globalsettings',
      name: 'globalsettings',
      component: GlobalSettingsView,
    },
    {
      path: '/depaer/:id/detaljer',
      name: 'depotDetails',
      component: DepotDetailsView,
    },
      {
      path: '/depaer/:id/kvitto',
      name: 'depotReceipt',
      component: DepotReceiptView,
    },
    {
      path: '/administration/konto',
      name: 'myAccount',
      component: MyAccountView,
    },
    {
      path: '/statistik',
      name: 'statistics',
      component: HomeView,
    },
    {
      path: '/bevakningar/ny',
      name: 'newKeepsake',
      component: NewKeepsakeView,
    },
    // TODO - finally the default route, when none of the above matches:
    // { path: '*', component: PageNotFoundComponent }
  ]
})


























































































import Vue from 'vue';
import {Business} from "@/interfaces/business/business";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {ENDPOINTS, get, put} from "@/api/mainApi";
import {Keepsake} from "@/interfaces/keepsake/keepsake";
import {Office} from "@/interfaces/office/office";
import {Depot} from "@/interfaces/depot/depot";
import {KeepsakeChangeOfficeRequest} from "@/interfaces/office/keepsakeChangeOfficeRequest";

export default Vue.extend({
  components: {},
  props: {
    value: Boolean,
    depot: Object as () => Depot,
    keepsakes: Array as () => Array<Keepsake>,
    selectedBusiness: Object as () => Business
  },
  data() {
    return {
      toBusiness: Object as () => Business,
      businesses: [] as Business[] | null,
      offices: [] as Array<Office>,
      loading: false,
      toOffice: Object as () => Office,
      loadingMoveKeepsakes: false,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      moveFormValid: false,
    }
  },
  computed: {
    active: {
      get(): Boolean {
        return this.value
      },
      set(value: Boolean) {
        this.$emit('update:value', value)
      }
    },
  },
  watch: {
    'selectedBusiness': {
      handler(val: Business) {
        if (val && val.id) {
          this.getBusinesses();
        }
      },
      immediate: true
    },
  },
  methods: {
    refreshDepots() {
      this.$emit('refresh-depots');
    },
    buildKeepsakeOfficeRequestList(): Array<KeepsakeChangeOfficeRequest> {
      let requests = [] as Array<KeepsakeChangeOfficeRequest>

      this.keepsakes.forEach((keepsake) => {
        requests.push({
          officeId: keepsake.office.id,
          depotId: this.depot.id,
          keepsakeId: keepsake.id
        })
      })
      return requests;
    },

    async getBusinesses() {
      this.loading = true
      get(ENDPOINTS.ORGANIZATIONS + "/" + this.selectedBusiness.organization.id + "/" + ENDPOINTS.BUSINESSES)
        .then((res: Array<Business>) => {
          this.businesses = res;
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    async getOffices(businessId) {
      this.loading = true
      get(ENDPOINTS.BUSINESSES + "/" + businessId + "/" + ENDPOINTS.OFFICES)
        .then((res: Array<Office>) => {
          this.offices = res;
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    closeDialog() {
      this.active = false
    },
    async moveKeepsakes() {

      if (this.$refs.moveForm.validate()) {
        this.loading = true
        let keepsakeChangeOfficeRequests = this.buildKeepsakeOfficeRequestList();

        try {

+          await put(ENDPOINTS.OFFICES + "/" + this.toOffice.id + "/" + ENDPOINTS.KEEPSAKES + "/" + ENDPOINTS.MOVE, {
            keepsakeChangeOfficeRequests
          });

            this.refreshDepots();

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Flyttade bevakningar till " + this.toOffice.name,
              timeout: 8000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
            this.closeDialog();
        } catch (err) {
          console.log(err);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att flyta bevakningar: " + err.data.msg,
            timeout: 8000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        } finally {
          this.false = false;
        }
      }
    }
  }
})

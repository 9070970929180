import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {Role} from "@/enums/role";

export function isHigherLevelAdmin(user: UserAccount): boolean {
  return isOrgAdmin(user) || isSysAdmin(user);
}

export function isAdmin(user: UserAccount): boolean {
  return isBusinessAdmin(user) || isOrgAdmin(user) || isSysAdmin(user);
}

export function isSysAdmin(user: UserAccount): boolean {
  return user && Role[user.role] === Role.SYSTEM_ADMIN;
}

export function isOrgAdmin(user: UserAccount): boolean {
  return user && Role[user.role] === Role.ORGANIZATION_ADMIN;
}

export function isBusinessAdmin(user: UserAccount): boolean {
  return user && Role[user.role] === Role.BUSINESS_ADMIN
}

export function isBusinessUser(user: UserAccount): boolean {
  return user && Role[user.role] === Role.BUSINESS_USER
}











































import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: "Åtkomst nekad"
    },
    message: {
      type: String,
      default: "Du har inte rättigheter att se denna sida"
    },
    showGoBackButton: {
      type: Boolean,
      default: true
    }
  }
})

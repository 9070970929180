import { TextAndDescription } from '@/interfaces/shared/textAndDescription';

export enum BusinessSetting {
  DEPOT_AGREEMENTS_DISABLED = 'DEPOT_AGREEMENTS_DISABLED',
}

export const BusinessSettingFeatureText: Record<BusinessSetting, TextAndDescription> = {
  [BusinessSetting.DEPOT_AGREEMENTS_DISABLED]: {
    text: 'Inaktivera depåavtal',
    description: 'Depåavtal ska inte kunna skapas eller redigeras.',
  },
};

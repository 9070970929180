export enum ActivationStatusType {
  NOT_ACTIVATED = 1,
  INITIATED = 2,
  ACTIVATED = 3,
}

export enum ActivationStatusTypeText {
  NOT_ACTIVATED = "NOT_ACTIVATED",
  INITIATED = "INITIATED",
  ACTIVATED = "ACTIVATED"
}


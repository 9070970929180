

































import Vue from 'vue';
import { Agreement } from '@/interfaces/depot/agreement';
import { hasOngoingAgreementSignatureAction } from '@/helpers/signatureHelper';
import { BankidSignatureType } from '@/interfaces/bankid/bankidSignatureType';
import { AgreementType } from '@/interfaces/depot/agreement';
import { AgreementRepository } from '@/repositories/agreementRepository';
import { SigningInProgressState } from '@/interfaces/bankid/signingInProgressState';
import DepotAgreementListItemComponent from '@/components/depot/details/agreement/DepotAgreementListItemComponent.vue';

export default Vue.extend({
  components: {
    DepotAgreementListItemComponent,
  },
  data() {
    return {
      signingSourceId: '2540069b-d00a-4c72-86f7-e7c64fb376a1',
      agreementRepository: new AgreementRepository(this),
    };
  },
  props: {
    agreements: Array as () => Array<Agreement>,
  },
  computed: {
    items(): Array<Agreement> { // we use computed here just to get some intellisense from interface
      return this.agreements;
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress
    },
    ongoingUndoSigningInProgress(): boolean {
      return hasOngoingAgreementSignatureAction(this.signingInProgress, BankidSignatureType.PERSONAL_DATA_AGREEMENT, this.signingSourceId, AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT);
    },
  },
  async mounted() {
    if (this.ongoingUndoSigningInProgress) {
      const agreementId = this.signingInProgress.undoExtraditionAgreementId
      await this.deleteAgreement(agreementId);
      this.removeSigning();
    }
  },
  methods: {
    async deleteAgreement(agreementId: number): Promise<void> {
      const response = await this.agreementRepository.deleteAgreement(agreementId);
      if (response) {
        //signed agreement to reload page and show keepsakes as not extradited
        const agreement = {
          id: agreementId,
          type: AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT,
        }
        this.signedAgreement(agreement);
      } 
    },
    removeSigning() {
      this.$store.commit('removeSigningInProgress');
    },
    refreshAgreements() {
      this.$emit('refreshAgreements');
    },
    signedAgreement(data) {
      this.$emit('signedAgreement', data);
    },
  },
});























import Vue from 'vue';
import {SearchResult} from "@/interfaces/searchResult";

export default Vue.extend({
    computed: {
        searchResult(): SearchResult {
            return this.item
        }
    },
    props: {
        item: {
            type: Object as () => SearchResult
        },
    },
    data() {
        return {}
    },
    methods: {},
})

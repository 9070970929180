





import Vue from 'vue';
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import SystemAdminHomeViewContentComponent from "@/views/home/content/SystemAdminHomeViewContentComponent.vue";
import BusinessHomeViewContentComponent from "@/views/home/content/BusinessHomeViewContentComponent.vue";
import {Role} from "@/enums/role";

export default Vue.extend({
    components: {
        SystemAdminHomeViewContentComponent,
        BusinessHomeViewContentComponent
    },
    data() {
        return {
        }
    },
    computed: {
        user(): UserAccount {
            return this.$store.state.userSession.userAccount
        },
        isSystemOrOrgAdmin(){
            return this.user.role === Role.SYSTEM_ADMIN || this.user.role === Role.ORGANIZATION_ADMIN
        },
        isUserOrBusinessAdmin(){
            return this.user.role === Role.BUSINESS_ADMIN || this.user.role === Role.BUSINESS_USER
        }
    }
})

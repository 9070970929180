// Helper functions during development
import {Role, RoleArray, RoleInterface} from "@/enums/role";
import {UserAccount} from "@/interfaces/useraccount/userAccount";

export default function selectableRoles(stateUser: UserAccount, roles: RoleInterface[]) {

    // System admin can select all roles
    if (stateUser.role === Role.SYSTEM_ADMIN) {
      return roles
    }
    // Org admin cannot select System admin
    else if (stateUser.role === Role.ORGANIZATION_ADMIN) {
      return roles.filter((role) => role.value !== Role.SYSTEM_ADMIN)
    }
    // Business admin cannot select higher Admin roles
    else {
      return roles.filter((role) => role.value !== Role.SYSTEM_ADMIN && role.value !== Role.ORGANIZATION_ADMIN)
    }
}

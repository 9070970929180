



























































































































































































import Vue from 'vue';
import { Office } from '@/interfaces/office/office';
import { NotificationSettings } from '@/interfaces/notificationsettings/notificationSettings';
import { Business } from '@/interfaces/business/business';
import { NOTIFICATIONTYPE } from '@/interfaces/notificationsettings/notificationSettings.ts';

import { ENDPOINTS, put } from '@/api/mainApi';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import BusinessSettingsComponent from '@/components/administration/BusinessSettingsComponent.vue';
import { BusinessSettings } from '@/interfaces/business/businessSettings';
import { BusinessSettingsRepository } from '@/repositories/businessSettingsRepository';
import BusinessMoveComponent from '@/components/administration/BusinessMoveComponent.vue';
import { isHigherLevelAdmin } from '@/helpers/userAccountHelper';
import { UserAccount } from '@/interfaces/useraccount/userAccount';

export default Vue.extend({
  components: { BusinessMoveComponent, BusinessSettingsComponent },
  props: {
    businessMoveStep: Number, // used to control when to show close button or not.
    editBusinessDialog: Boolean,
    businesses: Array as () => Array<Business>,
    selectedBusiness: Object as () => Business,
  },
  data() {
    return {
      NOTIFICATIONTYPE: NOTIFICATIONTYPE,
      menu: false,
      time: '',
      selected: null as Office | null,
      activeTab: 0,
      loading: false,
      creatingNew: false,
      emailRules: [
        v => !v || /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig',
      ],
      requiredRules: [
        v => !isNaN(parseFloat(v)) || 'Måste vara en siffra',
      ],
      newFormValid: false,
      notificationDelayFormValid: false,
      originalBusinessSettings: [] as Array<BusinessSettings>,
      selectedBusinessSettings: [] as Array<BusinessSettings>,
      businessSettingsRepository: new BusinessSettingsRepository(this),
    };
  },
  computed: {
    businessMoveStepLocal: {
      get() {
        return this.businessMoveStep;
      },
      set(val: boolean) {
        this.$emit('update:businessMoveStep', val);
      },
    },
    hideCloseButton(): boolean {
      //Last step of the move business dialog
      return this.businessMoveStep === 3 && this.activeTab === 2;
    },
    business() {
      return this.selectedBusiness;
    },
    user(): UserAccount {
      return this.$store.state.userSession.userAccount;
    },
  },
  watch:
    {
      creatingNew(val) {
        this.activeTab = val ? 1 : 0;
      }
      ,
      async selectedBusiness(val) {
        if (val) {
          await this.getBusinessSettings(val.id);
        }
      }
      ,
    }
  ,
  async mounted() {
    await this.getBusinessSettings(this.selectedBusiness.id);
  }
  ,
  methods: {
    closeDialog() {
      this.$emit('close');
    }
    ,
    setInitialBusiness() {
      this.$emit('setInitialBusiness');
    }
    ,
    closeMoveBusiness() {
      this.activeTab = 0;
    }
    ,
    isHigherLevelAdmin,
    updateNotificationSettings(notificationSettings
                                 :
                                 NotificationSettings,
    ) {
      if (this.$refs.notificationDelayForm.validate()) {
        this.loading = true;
        put(ENDPOINTS.NOTIFICATION_SETTINGS + '/' + notificationSettings.id, notificationSettings)
          .then((notificationSettings: NotificationSettings) => {
            let snackbar: Snackbar = {
              active: true,
              color: 'success',
              text: 'Sparade ändringar för fördröjning av påminnelse',
              timeout: 8000,
              action: null,
            };
            this.$store.commit('setSnackbar', snackbar);
          }).catch(err => {
          let snackbar: Snackbar = {
            active: true,
            color: 'error',
            text: 'Misslyckades med att uppdatera notifikationsinställning: ' + err.data.msg,
            timeout: 10000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);
        }).finally(() => {
          this.loading = false;
        });
      }
    }
    ,
    async updateBusinessSettings(selectedBusinessSettings
                                   :
                                   Array<BusinessSettings>,
    ) {
      this.loading = true;

      try {
        const tasks: Array<Promise<BusinessSettings>> = [];

        const selectedFeatures = new Set(selectedBusinessSettings.map(s => s.activatedSetting));
        const originalFeatures = new Set(this.originalBusinessSettings.map(o => o.activatedSetting));

        // If the original business settings have a setting that is not in the selected business settings, delete it
        for (const originalBusinessSetting of this.originalBusinessSettings) {
          if (!selectedFeatures.has(originalBusinessSetting.activatedSetting)) {
            tasks.push(this.businessSettingsRepository.deleteBusinessSettings(originalBusinessSetting.id));
          }
        }

        // If the selected business settings have a setting that is not in the original business settings, add it.
        for (const selectedBusinessSetting of selectedBusinessSettings) {
          if (!originalFeatures.has(selectedBusinessSetting.activatedSetting)) {
            tasks.push(this.businessSettingsRepository.createBusinessSettings(selectedBusinessSetting));
          }
        }

        await Promise.all(tasks);

        this.originalBusinessSettings = [...selectedBusinessSettings];
      } finally {
        this.loading = false;
      }
    }
    ,
    async getBusinessSettings(businessId: number) {
      this.loading = true;
      try {
        this.originalBusinessSettings = await this.businessSettingsRepository.getBusinessSettingsByBusinessId(businessId);
        this.selectedBusinessSettings = [...this.originalBusinessSettings];
      } finally {
        this.loading = false;
      }
    }
    ,
  }
  ,
})
;

export enum PrivacyType {
    ALWAYS_SHOWN = "ALWAYS_SHOWN",
    SHOWN_AFTER_DEATH = "SHOWN_AFTER_DEATH",
    NEVER_SHOWN = "NEVER_SHOWN",
}

export enum PrivacyTypeStr {
    ALWAYS_SHOWN = "Visas alltid",
    SHOWN_AFTER_DEATH = "Visas efter dödsfall",
    NEVER_SHOWN = "Visas aldrig",
}


export const PrivacyTypeArr = [
    {value: PrivacyType.ALWAYS_SHOWN, text: PrivacyTypeStr.ALWAYS_SHOWN},
    {value: PrivacyType.SHOWN_AFTER_DEATH, text: PrivacyTypeStr.SHOWN_AFTER_DEATH},
    {value: PrivacyType.NEVER_SHOWN, text: PrivacyTypeStr.NEVER_SHOWN},
]

export enum CountryCode {
  SWEDEN = 1,
  NORWAY = 2,
}

export enum CountryName {
  SWEDEN = "Sverige",
  NORWAY = "Norge",
}
export const CountryArray = [
  {
    text: CountryName.SWEDEN,
    value: CountryCode.SWEDEN
  },
  {
    text: CountryName.NORWAY,
    value: CountryCode.NORWAY
  }
]

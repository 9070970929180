

import Vue from "vue";
import {
  PersonalDataProcessingAgreementType
} from "@/enums/personalDataAgreementType";
import {BASE_URL} from "@/api/mainApi";
import {Dialog} from "@/interfaces/presentation/dialog";
import {PersonalDataProcessingAgreementRepository} from "@/repositories/personalDataProcessingAgreementRepository";
import {SigningInProgressState} from "@/interfaces/bankid/signingInProgressState";
import {BankidSignatureType} from "@/interfaces/bankid/bankidSignatureType";

export default Vue.extend({
  components: {},
  props: {},
  data() {
    return {
      personalDataAgreementRepository: new PersonalDataProcessingAgreementRepository(this),
      signingSourceId: "b2e902e9-cf8b-47d8-a0fc-b3e67b856fda"
    }
  },
  computed: {
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress
    },
    confirmPasswordDialog(): Dialog {
      return {
        active: true,
        actionClick: () => {
          this.signDataProcessingAgreement(PersonalDataProcessingAgreementType.PERSONAL_DATA_ASSISTANT)
        },
        closeClick: () => {
          this.resetDialogs()
        },
        title: "Slutför signering av personuppgiftsbiträdesavtal",
        text: "Är du helt säker på att du vill signera personuppgiftsbiträdesavtalet?" + "\n\n",
      };
    },
    personalDataAgreementDialog(): Dialog {
      return {
        active: true,
        actionBtnText: "Jag godkänner avtalet",
        title: "Personuppgiftsbiträdesavtal",
        text: "För att använda dig av tjänsten behöver du godkänna vårt personuppgiftsbiträdesavtal.",
        maxWidth: 1000,
        pdfSrc: this.personalDataAgreementRepository.getPersonalDataAgreementUrlByType(PersonalDataProcessingAgreementType.PERSONAL_DATA_ASSISTANT),
        overlay: true,
        actionClick: () => {
          this.setSigningStatusInStore()
          setTimeout(() => {
            this.$store.commit('setCredentialsConfirmDialog', this.confirmPasswordDialog);
          }, 200)
        },
      }
    }
  },
  methods: {
    setSigningStatusInStore() {
      // prepare the signing by adding data for it, but it is not yet ready
      let signingInProgressState: SigningInProgressState = {
        personalAgreementSigning: true,
        bankidSignatureType: BankidSignatureType.PERSONAL_DATA_AGREEMENT,
        ready: false,
        sourceId: this.signingSourceId
      }

      this.$store.commit('setSigningInProgress', signingInProgressState)
    },
    resetDialogs() {
      let agreementDialog = this.personalDataAgreementDialog;
      agreementDialog.active = true;

      let confirmPasswordDialog = this.confirmPasswordDialog;
      confirmPasswordDialog.active = true;

      this.$store.commit('setDialog', agreementDialog)
    },
    async handleSignedAgreement(signatureId: string){
      await this.signDataProcessingAgreement(PersonalDataProcessingAgreementType.PERSONAL_DATA_ASSISTANT, signatureId);
    },
    async handleDataProcessingAgreement() {
      const response = await this.personalDataAgreementRepository.getIsPersonalDataProcessingAgreementsSignedByType(PersonalDataProcessingAgreementType.PERSONAL_DATA_ASSISTANT);

      if (!response) {
        this.$store.commit('setDialog', this.personalDataAgreementDialog)
      }
    },
    async signDataProcessingAgreement(type: PersonalDataProcessingAgreementType, signatureId?: string) {
      let bankIdSignatureParam = signatureId ? "?signatureId=" + signatureId : null;
      await this.personalDataAgreementRepository.signProcessingAgreementsByType(type, bankIdSignatureParam)
      location.href = BASE_URL;
    },
  }
});









import Vue from "vue";
import {deleteRequest, ENDPOINTS, get, LOGIN_PAGE_URL} from "@/api/mainApi";
import {UserSession} from "@/interfaces/useraccount/userSession";
import MainApp from "@/apps/MainApp.vue";
import LoginView from "@/views/external/LoginView.vue";

export default Vue.extend({
    components: {MainApp, LoginView},
    data() {
        return {};
    },
    computed: {
        initialRoute() // is not reactive
        {
            return this.$router.currentRoute.name
        },
    },
    methods: {
    }
});






































import Vue from "vue";
import {Office} from "@/interfaces/office/office";

export default Vue.extend({
    components: {},
    props: {
        dialogValue: Boolean,
        showCancel: Boolean,
        offices: Array as () => Array<Office>
    },
    data() {
        return {
            requiredRules: [
                v => !!v || 'Obligatoriskt fält',
            ],
            office: null as Object | null
        }
    },
    computed: {
        active: {
            get(): boolean {
                return this.dialogValue
            },
            set(dialogValue: boolean) {
                this.$emit('update:dialogValue', dialogValue)
            }
        }
    },
    methods: {
        saved() {
            if (this.$refs.form.validate()) {
                this.$emit('saved', this.office)
                this.closeDialog()
            }
        },
        deleted() {
            this.$emit('deleted', this.office)
            this.closeDialog()
        },
        closeDialog() {
            this.active = false
        },
    }
});



































import Vue from 'vue';
import DepotAgreementListComponent from '@/components/depot/details/agreement/DepotAgreementListComponent.vue';
import { Agreement } from '@/interfaces/depot/agreement';
import { Business } from '@/interfaces/business/business';
import { AgreementRepository } from '@/repositories/agreementRepository';

export default Vue.extend({
  components: { DepotAgreementListComponent },
  props: {
    depotId: {
      type: Number,
      required: true,
    },
    business: Object as () => Business,
  },
  data() {
    return {
      agreements: [] as Array<Agreement>,
      loading: false,
      agreementRepository: new AgreementRepository(this),
    };
  },
  computed: {
    totalNumberOfAgreements(): number {
      return this.agreements.length;
    },
    hasUnsignedAgreements(): boolean {
      return this.agreements.some((agreement: Agreement) => !!agreement.agreementSignature);
    },
  },
  watch: {
    business(value: Business) {
      if (value) {
        this.getAgreementsForDepot(false);
      }
    },
  },
  mounted() {
    this.getAgreementsForDepot(true);
  },
  methods: {
    signedAgreement(data) {
      this.$emit('signedAgreement', data);
    },
    async getAgreementsForDepot(mounted: boolean) {
      if (this.business) {
        this.loading = true;
        const fetchedAgreements: Agreement[] = await this.agreementRepository.getDepotAgreements(this.depotId, this.business.id);
        if (fetchedAgreements && fetchedAgreements.length) {
          this.agreements = fetchedAgreements;
          if (!mounted) {
            this.$emit('refreshAgreements');
          }
        }
        this.loading = false;
      }
    },
  },
});

import {Business} from "@/interfaces/business/business";

export interface NotificationSettings {
  id: number;
  name: string;
  notificationDelayDays: number;
  notificationType: number;
  business: Business;
}

export enum NOTIFICATIONTYPE {
  UNOPENED_DEPOT_WITH_DECEASED = 1,
}




















import Vue from 'vue';
import { BusinessSettings } from '@/interfaces/business/businessSettings';
import { BusinessSetting, BusinessSettingFeatureText } from '@/enums/businessSetting';
import { Business } from '@/interfaces/business/business';

export default Vue.extend({
  name: 'BusinessSettings',
  props: {
    enabledSettings: {
      type: Array as () => Array<BusinessSettings>,
      default: () => [],
    },
    business: {
      type: Object as () => Business,
      required: true,
    },
  },
  data() {
    return {
      availableBusinessSettings: Object.values(BusinessSetting)
        .map((value) => {
          return {
            activatedSetting: value,
            text: BusinessSettingFeatureText[value].text,
            description: BusinessSettingFeatureText[value].description,
          };
        }) as Array<{ activatedSetting: string, text: string, description: string }>,
    };
  },
  computed: {
    selectedBusinessSettings: {
      get(): Array<{ activatedSetting: string, text: string, description: string }> {
        return this.enabledSettings
          .map((setting: BusinessSettings) => {
            return {
              activatedSetting: setting.activatedSetting,
              text: BusinessSettingFeatureText[setting.activatedSetting].text,
              description: BusinessSettingFeatureText[setting.activatedSetting].description,
            };
          });
      },
      set(newValue: Array<{ activatedSetting: string, text: string, description: string }>) {
        // Remove the settings that are not selected
        const settingsToRemove = this.enabledSettings.filter((setting: BusinessSettings) => {
          return !newValue.some((value) => value.activatedSetting === setting.activatedSetting);
        });
        settingsToRemove.forEach((setting: BusinessSettings) => {
          const index = this.enabledSettings.indexOf(setting);
          if (index > -1) {
            this.enabledSettings.splice(index, 1);
          }
        });

        // Add the settings that are selected if not there
        newValue.forEach((value) => {
          if (!this.enabledSettings.some((setting: BusinessSettings) => setting.activatedSetting === value.activatedSetting)) {
            this.enabledSettings.push({
              activatedSetting: value.activatedSetting,
              business: this.business,
              id: 0,
              updatedAt: null,
              createdAt: null,
            } as BusinessSettings);
          }
        });
        this.$emit('update:selectedBusinessSettings', newValue);
      },
    },
  },
});

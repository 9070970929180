export enum AttachedFileType {
    OPTIONAL = "OPTIONAL",
    MANDATORY = "MANDATORY",
    NONE = "NONE",
}

export enum AttachedFileTypeStr {
    OPTIONAL = "Valfritt",
    MANDATORY = "Obligatoriskt",
    NONE = "Ingen",
}


export const AttachedFileTypeArr = [
    {value: AttachedFileType.OPTIONAL, text: AttachedFileTypeStr.OPTIONAL},
    {value: AttachedFileType.MANDATORY, text: AttachedFileTypeStr.MANDATORY},
    {value: AttachedFileType.NONE, text: AttachedFileTypeStr.NONE},
]

import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import matches from 'validator/lib/matches';

export const isString = (v: any) => (typeof v == 'string');

export const pidPattern = /^(19|20)[0-9]{6}[-\s]?[0-9]{4}$/;

export default function validators() {
    return {
        required: (v: any) => !!v || "Obligatoriskt fält",
        isNotEmpty: (v: any) => (isString(v) && !isEmpty(v, {ignore_whitespace: true})) || "Obligatoriskt fält",
        email: (v: any) => !v || isEmail(v) || "Ogiltig e-postadress",
        // NOTE: This is a swedish pid check, need to adjust if used in other localizations
        pid: (v: any) => !isString(v) || matches(v, pidPattern) || "Ogiltigt personnummer"
    };
}

































import Vue from 'vue';
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import UserAccountDetailsComponent from "@/components/useraccount/UserAccountDetailsComponent.vue";

export default Vue.extend({
  components: {UserAccountDetailsComponent},
  data() {
    return {}
  },
  computed: {
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
  }
})














































































































import Vue from 'vue';
import {Depot} from "@/interfaces/depot/depot";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {ENDPOINTS, get} from "@/api/mainApi";
import NotFoundErrorComponent from "@/components/NotFoundErrorComponent.vue";
import {Office} from "@/interfaces/office/office";
import DepotKeepsakesCardComponent from "@/components/depot/details/keepsake/DepotKeepsakesCardComponent.vue";
import * as url from "url";
import dayjs from 'dayjs';

export default Vue.extend({
  components: {
    NotFoundErrorComponent,
    DepotKeepsakesCardComponent
  },
  data() {
    return {
      loading: true,
      error: false,
      depot: null as Depot | null
    }
  },
  computed: {
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    office(): Office {
      return this.stateUser.office
    },
    currentDateTime() {
      return dayjs().format("YYYY-MM-DD HH:mm")
    },
  },
  mounted() {
    let depotId = this.$route.params.id
    this.getDepot(depotId)
  },
  methods: {
    async getDepot(id) {
      this.loading = true
      try {

        this.depot = await get(ENDPOINTS.DEPOTS + "/" + id)
      } catch (e) {
        this.error = true
      }
      this.loading = false
    },
    goToDepot() {
      this.$router.push({name: 'depotDetails', params: {id: this.depot.id}})
    },
    printReceipt() {
      window.print();
    }
  }
})

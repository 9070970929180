




































import Vue from 'vue';
import SearchBarComponent from "@/components/search/SearchBarComponent.vue";

export default Vue.extend({
  components: {
    SearchBarComponent,
  },
  props: {
    drawer: {
      type: Boolean,
    }
  },
  computed: {
    navDrawer: {
      get: function () {
        return this.drawer;
      },
      set: function (newValue) {
        this.$emit('update:drawer', newValue)
      }
    },
    environmentText() {
      return process.env.VUE_APP_ENV_NAME ? `${process.env.VUE_APP_ENV_NAME}` : ""
    }
  },

})
































































import Vue from "vue";
import {Depot} from "@/interfaces/depot/depot";

export default Vue.extend({
  components: {},
  props: {
    value: Boolean,
    title: String,
    subtitle: String,
    depots: Array as () => Array<Depot>
  },
  data() {
    return {
      selected: null as Depot | null
    }
  },
  computed: {
    active: {
      get(): Boolean {
        return this.value
      },
      set(value: Boolean) {
        this.$emit('update:value', value)
      }
    },
  },
  methods: {
    goToDepot(depot: Depot) {
      this.$router.push({name: 'depotDetails', params: {id: depot.id}})
    },
    closeDialog() {
      this.active = false
    },
  },
});










































































import Vue from "vue";
import {Keepsake} from "@/interfaces/keepsake/keepsake";
import KeepsakeDialogComponent from "@/components/keepsake/KeepsakeDialogComponent.vue";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Depot} from "@/interfaces/depot/depot";
import {FileData} from "@/interfaces/fileData";

export default Vue.extend({
    components: {
        KeepsakeDialogComponent
    },
    data() {
        return {
            selectedKeepsake: null as Keepsake | null,
            keepsakeDialog: false,
            displayNameMaxLengthThreshold: 50
        }
    },
    props: {
        keepsakes: Array as () => Array<Keepsake>,
        selected: Array as () => Array<Number>,
        depots: Array as () => Array<Depot>
    },
    computed: {
        items: {
            get(): Array<Keepsake> {
                return this.keepsakes
            },
            set(value: Array<Keepsake>) {
                this.$emit('update:keepsakes', value)
            }
        },
        editing() {
            return this.selectedKeepsake && this.selectedKeepsake.index >= 0
        }
    },
    methods: {
        keepsakeDialogSaved(val: Keepsake) {
            if (!this.editing) {
                val.index = this.keepsakes.length
                this.keepsakes.push(Object.assign({}, val))
            }
        },
        getDisplayedFileName(fileData: FileData) {

          if (fileData.displayName.length > 0) {

            if (fileData.displayName.length > this.displayNameMaxLengthThreshold) {
              return fileData.displayName.substring(0, this.displayNameMaxLengthThreshold);
            }

            return fileData.displayName;
          }

          return fileData.filename;
        },
        keepsakeDialogDeleted(val: Keepsake) {
            let snackbar: Snackbar = {
                active: true,
                color: "primary",
                text: "Bevakning borttagen",
                timeout: 7000,
                action: null
            };
            this.$store.commit('setSnackbar', snackbar);
            this.items.splice(val.index, 1)
        },
        selectKeepsake(val: Keepsake) {
            this.selectedKeepsake = val
            this.keepsakeDialog = true
        },
        newKeepsake() {
            this.selectedKeepsake = {
                description: "",
                fileData: null,
                attachedFile: false,
                storagePlace: null,
                keepsakeType: null,
            }
            this.keepsakeDialog = true
        }
    }
});

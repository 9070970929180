






































































import Vue from "vue";
import {Depot} from "@/interfaces/depot/depot";
import SocialSecurityNumberInputComponent from "@/components/form/SocialSecurityNumberInputComponent.vue";
import validator from "validator";
import {pidPattern} from "@/validators";
import { ENDPOINTS, get, getService } from '@/api/mainApi';
import {RequestResponse} from "@/interfaces/requestResponse";
import {DepotInfo} from "@/interfaces/depot/depotInfo";
import {Keepsake} from "@/interfaces/keepsake/keepsake";
import matches = validator.matches;
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Dialog} from "@/interfaces/presentation/dialog";

export default Vue.extend({
  components: {SocialSecurityNumberInputComponent},
  data() {
    return {
      sharedOptions: [{text: "1 Person", value: 1}, {text: "2 Personer", value: 2}],
      sharedOption: 1,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      depot1SsnError: true,
      depot2SsnError: true,
      depot1InstanceIdentifier: 1,
      depot2InstanceIdentifier: 2,
    }
  },
  props: {
    depot1: Object as () => Depot,
    depot2: Object as () => Depot || null,
    addedKeepsakes: Array as () => Array<Keepsake> || null,
    flat: Boolean,
    outlined: Boolean,
    loading: Boolean,
    shared: Boolean,
  },
  computed: {
    firstDepot: {
      get(): Depot {
        return this.depot1
      },
      set(value: Depot) {
        this.$emit('update:depot1', value)
      }
    },
    secondDepot: {
      get(): Depot {
        return this.depot2
      },
      set(value: Depot) {
        this.$emit('update:depot2', value)
      }
    },
    sharedBetweenDepots: {
      get(): Boolean {
        return this.shared
      },
      set(value: Boolean) {
        this.$emit('update:shared', value)
      }
    },
    depot1SsnErrorMsg() {
      return this.depot1SsnError ? ["Personnummer kunde inte valideras"] : null
    },
    depot2SsnErrorMsg() {
      return this.depot2SsnError ? ["Personnummer kunde inte valideras"] : null
    },
    depot2KeepsakesAdded() {
      // return true if there are keepsakes with depot2 as one of their depots
      let result = 0
      if (this.addedKeepsakes && this.secondDepot) {
        this.addedKeepsakes.forEach((keepsake: Keepsake) => {
            if (keepsake.depots.findIndex(depot => depot.socialSecurityNumber === this.secondDepot.socialSecurityNumber) >= 0) {
              result++
            }
          }
        )
      }
      return result > 0
    },
    firstDepotLocked() {
      return this.firstDepot.id && this.firstDepot.id > 0
    },
    secondDepotLocked() {
      return this.secondDepot.id && this.secondDepot.id > 0
    }
  },
  watch: {
    'firstDepot.socialSecurityNumber': async function (val) {
      if (val) {
        this.depot1SsnError = true
        if (matches(val, pidPattern) && (!this.depot2 || val !== this.depot2.socialSecurityNumber)) {
          let valid = await this.checkSsnValid(val)
          if (valid) {
            this.depot1SsnError = false
            // check if a depot exists with provided ssn
            let depotInfoData: Array<DepotInfo> = await this.getDepotInfoFromSsn(val)
            if (depotInfoData && depotInfoData.length > 0) {
              this.firstDepot = depotInfoData[0];
            }
          } else {
            this.depot1SsnError = true
          }
        }
      }
    },
    'secondDepot.socialSecurityNumber': async function (val) {
      if (val) {
        this.depot2SsnError = true
        if (matches(val, pidPattern) && val !== this.depot1.socialSecurityNumber) {
          let valid = await this.checkSsnValid(val)
          if (valid) {
            this.depot2SsnError = false
            // check if a depot exists with provided ssn
            let depotInfoData: Array<DepotInfo> = await this.getDepotInfoFromSsn(val)
            if (depotInfoData && depotInfoData.length > 0) {
              this.secondDepot = depotInfoData[0];
            }
          } else {
            this.depot2SsnError = true
          }
        }
      }
    }
  },
  methods: {
    shareSettingsChanged(val) {
      this.secondDepot = {
        name: "",
        socialSecurityNumber: "",
      } as Depot
      this.sharedBetweenDepots = val !== 1;
      if (this.sharedBetweenDepots) {
        this.secondDepot = null
      }
    },
    async checkSsnValid(ssn: string) {
      // backend call to make sure ssn is valid
      let response: RequestResponse | null = null
      response = await getService(ENDPOINTS.SOCIAL_SECURITY_NUMBER + "/" + ssn + "/" + ENDPOINTS.IS_VALID)
      if (response) {
        return (response.msg == 'true') // msg is string but returns "true" or "false" in this instance
      }
      return false
    },
    async getDepotInfoFromSsn(ssn: string) {
      try {
        return await get(ENDPOINTS.SEARCH + '/' + ENDPOINTS.DEPOT + '/' + ssn);
      } catch (e) {
      } // ignore not found error
      return null
    },
    clearSsnField(depot: Depot, depotInstance: number) {

      if (depot) {
        let clearDepot = {
          name: "",
          socialSecurityNumber: "",
        } as Depot
        if (depotInstance === this.depot1InstanceIdentifier) {
          this.firstDepot = clearDepot;
        } if (depotInstance === this.depot2InstanceIdentifier) {
          this.secondDepot = clearDepot;
        }
        this.$emit("clear-ssn", clearDepot);
      }
    },
  }
});

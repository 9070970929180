import { BankidSignatureType } from '@/interfaces/bankid/bankidSignatureType';
import { SigningInProgressState } from '@/interfaces/bankid/signingInProgressState';
import { AgreementType } from '@/interfaces/depot/agreement';

export function hasOngoingSignatureAction(signingInProgressState: SigningInProgressState,
                                          bankidSignatureType: BankidSignatureType, signingSourceId: string) {

  return !!signingInProgressState
         && signingInProgressState.bankidSignatureType === bankidSignatureType
         && signingInProgressState.ready
         && signingInProgressState.sourceId === signingSourceId;
}

export function hasOngoingAgreementSignatureAction(signingInProgressState: SigningInProgressState,
                                                   bankidSignatureType: BankidSignatureType, signingSourceId: string, agreementType: AgreementType) {

  return !!signingInProgressState
         && signingInProgressState.bankidSignatureType === bankidSignatureType
         && signingInProgressState.ready
         && signingInProgressState.sourceId === signingSourceId
         && signingInProgressState.agreementType === agreementType;
}

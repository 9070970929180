






























































































































































































































































import Vue from 'vue';
import {Organization} from "@/interfaces/organization/organization";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {CountryArray, CountryCode} from "@/enums/country";
import KeepsakeTypeAdminComponent from "@/components/administration/KeepsakeTypeAdminComponent.vue";
import {ENDPOINTS, get, post, put} from "@/api/mainApi";
import ExtendedOrganizationAdminComponent from "@/components/administration/ExtendedOrganizationAdminComponent.vue";

export default Vue.extend({
  components: {KeepsakeTypeAdminComponent, ExtendedOrganizationAdminComponent},
  props: {
    value: Object as () => Organization
  },
  data() {
    return {
      countries: CountryArray,
      editKeepsakeTypesDialog: false,
      organizations: [] as Organization[],
      newOrg: {
        name: "",
        country: CountryCode.SWEDEN,
        email: "",
        phone: "",
        organizationNumber: ""
      } as Organization | null,
      newDialog: false,
      registrationLinkDialog: false,
      agreementTextsDialog: false,
      placesSettingsDialog: false,
      newFormValid: false,
      loading: false,
      selectedFormValid: false,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      emailRules: [
        v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
      ],
    }
  },
  computed: {
    selectedOrganization: {
      get(): Organization {
        return this.value
      },
      set(val: Organization) {
        this.$emit('update:value', val)
      }
    },
  },
  watch: {
    organizations: {
      deep: true,
      immediate: true,
      handler(val: Organization[]) {
        if (val && val.length > 0) {
          if (this.selectedOrganization) {
            this.organizations.forEach(o => o.active = false);
            let org = this.organizations.find(o => o.id === this.selectedOrganization.id)
            if (org) {
              org.active = true;
            }
          }
        }
      }
    },
  },
  created() {
    this.getOrganizations();
  },
  methods: {
    selectOrganization(organization) {
      this.selectedOrganization = organization;
      this.organizations.forEach(o => o.active = false);
      organization.active = true;
    },
    async getOrganizations() {
      this.loading = true;
      get(ENDPOINTS.ORGANIZATIONS,
      ).then((organizations: Array<Organization>) => {
        this.organizations = organizations;
        this.organizations.forEach(o => {
          if (o.countryCode) {
            o.country = CountryCode[o.countryCode];
          }
        })
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateOrganization(organization: Organization) {

      this.$refs.selectedForm.validate();

      if (this.selectedFormValid) {

        organization.countryCode = CountryCode[organization.country];

        put(ENDPOINTS.ORGANIZATIONS + "/" + organization.id, organization)
          .then((org: Organization) => {
            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Sparade ändringar för " + org.name,
              timeout: 8000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
          }).catch(err => {
          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att uppdatera organisation: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
          this.newDialog = false
        })
      }
    },
    addOrganization() {
      if (this.$refs.newForm.validate()) {

        this.newOrg.countryCode = CountryCode[this.newOrg.country];

        post(ENDPOINTS.ORGANIZATIONS, this.newOrg)
          .then((organization: Organization) => {
            this.organizations.push(organization);

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "La till " + this.newOrg.name,
              timeout: 8000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar);

            this.newOrg = {
              name: "",
              country: CountryCode.SWEDEN,
              email: "",
              phone: "",
              organizationNumber: ""
            }

            this.$store.commit('setSnackbar', snackbar)

          }).catch(err => {
          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att lägga till organisation: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
          this.newDialog = false
        })
      }
    }
  }
})

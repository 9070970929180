
























































import Vue from 'vue';
import SparStatisticsSummaryTableComponent from '@/components/spar/SparStatisticsSummaryTableComponent.vue';
import DepotDataTableComponent from '@/components/depot/DepotDataTableComponent.vue';
import StatusSelectComponent from '@/components/depot/status/StatusSelectComponent.vue';
import UserAccountRequestsComponent from '@/components/useraccount/UserAccountRequestsComponent.vue';
import { Status } from '@/interfaces/depot/depot';

export default Vue.extend({
  components: {
    SparStatisticsSummaryTableComponent,
    DepotDataTableComponent,
    StatusSelectComponent,
    UserAccountRequestsComponent,
  },
  data() {
    return {
      depotFilterStatuses: [Status.MARKED_AS_DECEASED_BY_SPAR, Status.MARKED_AS_DECEASED_BY_USER, Status.DECEASED_WORK_IN_PROGRESS],
    };
  },
  methods: {},
});

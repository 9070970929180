









































































































































import Vue from 'vue';
import {Depot} from "@/interfaces/depot/depot";
import DepotPersonalDataCardComponent from "@/components/depot/details/DepotPersonalDataCardComponent.vue";
import DepotStatusCardComponent from "@/components/depot/details/DepotStatusCardComponent.vue";
import {DepotKeepsakeContainer} from "@/interfaces/keepsake/depotKeepsakeContainer.ts";
import DepotKeepsakesCardComponent from "@/components/depot/details/keepsake/DepotKeepsakesCardComponent.vue";
import DepotEventLogComponent from "@/components/depot/details/eventlog/DepotEventLogComponent.vue";
import DepotAgreementsComponent from "@/components/depot/details/agreement/DepotAgreementsComponent.vue";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {ENDPOINTS, get, put} from "@/api/mainApi";
import NotFoundErrorComponent from "@/components/NotFoundErrorComponent.vue";
import {Business} from "@/interfaces/business/business";
import {Agreement, AgreementType} from "@/interfaces/depot/agreement";
import {UserSession} from "@/interfaces/useraccount/userSession";
import {SigningInProgressState} from "@/interfaces/bankid/signingInProgressState";
import {isHigherLevelAdmin, isOrgAdmin, isSysAdmin} from "@/helpers/userAccountHelper";

export default Vue.extend({
  components: {
    NotFoundErrorComponent,
    DepotPersonalDataCardComponent,
    DepotStatusCardComponent,
    DepotKeepsakesCardComponent,
    DepotEventLogComponent,
    DepotAgreementsComponent
  },
  data() {
    return {
      loading: true,
      error: false,
      refreshEvents: false,
      depot: null as Depot | null,
      refreshAgreementData: false,
      business: null as Business | null
    }
  },
  computed: {
    isAllowedToOpen(): boolean {
      return this.depot && this.depot.adminRoleRequired ? isHigherLevelAdmin(this.stateUser) : true;
    },
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    isUserBusinessActive(): boolean {
      return this.stateUser && this.business && this.stateUser.business.id === this.business.id;
    },
    // whether to show info regarding agreements or events
    showContextualDepotInfo(): boolean {
      return this.stateUser && (this.isUserBusinessActive || isSysAdmin || isOrgAdmin)
    },
    session(): UserSession {
      return this.$store.state.userSession
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress
    }
  },
  async mounted() {
    this.loading = true;
    let depotId = this.$route.params.id
    await this.getDepot(depotId, true);
    if (!this.business) {
      this.business = this.stateUser.business;
    }
    this.loading = false;
  },
  methods: {
    async getDepot(id, depotOpened: boolean) {
      this.loading = true
      try {
        this.depot = await get(ENDPOINTS.DEPOTS + "/" + id, { includePlannedArchivalDate: true });
        this.sortKeepsakeOffices(this.depot.keepsakes);
        if (depotOpened) {
          await this.markAsOpened();
        }
      } catch (e) {
        this.error = true
      }
      this.loading = false
    },
    reload(depotOpened: boolean) {
      this.getDepot(this.depot.id, depotOpened);
    },
    async markAsOpened() {
      await put(ENDPOINTS.DEPOTS + "/" + this.depot.id + "/" + ENDPOINTS.OPENED, {})
    },
    reloadEvents() {
      this.refreshEvents = true;
    },
    refreshAgreements() {
      this.refreshAgreementData = true;

      setTimeout(() => {
        this.refreshAgreementData = false;
      })
    },
    signedAgreement(data: Agreement) {
      if (data.type === AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT) {
        this.reload(true);
      }
    },
    businessTabChanged(business: Business) {
      this.business = business;
    },
    sortKeepsakeOffices(officeKeepsakes: Array<DepotKeepsakeContainer>) { // sorts keepsakes so the first tab is always users current office
      if (this.stateUser.office) {
        for (let i = 0; i < officeKeepsakes.length; i++) {
          if (officeKeepsakes[i].office.business.id === this.stateUser.business.id && officeKeepsakes[i].office.id === this.stateUser.office.id) {
            let obj = officeKeepsakes.splice(i, 1);   // removes the item
            officeKeepsakes.unshift(obj[0]);         // adds it back to the beginning
            break;
          }
        }
      }
    }
  }
})

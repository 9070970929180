export enum StorageType {
    NOT_STORED = "NOT_STORED",
    ORIGINAL = "ORIGINAL",
    COPY = "COPY",
}

export enum StorageTypeStr {
    NOT_STORED = "Förvaras ej",
    ORIGINAL = "Original",
    COPY = "Kopia",
}


export const StorageTypeArr = [
    {value: StorageType.NOT_STORED, text: StorageTypeStr.NOT_STORED},
    {value: StorageType.ORIGINAL, text: StorageTypeStr.ORIGINAL},
    {value: StorageType.COPY, text: StorageTypeStr.COPY},
]













































































































































































































































































































































import Vue from 'vue';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { Organization } from '@/interfaces/organization/organization';
import { KeepsakeType } from '@/interfaces/keepsake/keepsakeType';
import { AttachedFileType, AttachedFileTypeArr } from '@/interfaces/keepsake/enums/attachedFileType';
import { StorageType, StorageTypeArr } from '@/interfaces/keepsake/enums/storageType';
import { AccessType, AccessTypeArr } from '@/interfaces/keepsake/enums/accessType';
import { PrivacyType, PrivacyTypeArr } from '@/interfaces/keepsake/enums/privacyType';
import { deleteRequest, ENDPOINTS, get, post, put } from '@/api/mainApi';
import { Dialog } from '@/interfaces/presentation/dialog';

export default Vue.extend({
  components: {},
  props: {
    selectedOrganization: Object as () => Organization,
    showInlined: Boolean,
  },
  data() {
    return {
      selected: null as KeepsakeType | null,
      loading: false,
      newKeepsakeType: null as KeepsakeType | null,
      creatingNew: false,
      activeTab: 0,
      emailRules: [
        v => !v || /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig',
      ],
      requiredRules: [
        v => !!v || 'Obligatoriskt fält',
      ],
      newFormValid: false,
      selectedFormValid: false,
      storageTypes: StorageTypeArr,
      attachedFileTypes: AttachedFileTypeArr,
      privacyTypes: PrivacyTypeArr,
      accessTypes: AccessTypeArr,
      keepsakeTypes: [] as Array<KeepsakeType>,
    };
  },
  computed: {
    organization() {
      return this.selectedOrganization;
    },
    sortedKeepsakeTypes(): Array<KeepsakeType> {
      return this.keepsakeTypes.toSorted((a: KeepsakeType, b: KeepsakeType) => (a.sortOrder || 0) - (b.sortOrder || 0));
    },
  },
  watch: {
    creatingNew(val) {
      this.activeTab = val ? 1 : 0;
    },
    selectedOrganization(val: Organization) {
      if (val && val.id) {
        this.selected = null;
        this.getKeepsakeTypes(val.id);
        this.initNewKeepsakeType();
      }
    },
  },
  created() {
    this.getKeepsakeTypes(this.organization.id);
    this.initNewKeepsakeType();
  },
  methods: {
    initNewKeepsakeType() {
      this.newKeepsakeType = {
        hasText: false,
        sharable: true,
        attachedFileType: AttachedFileType.OPTIONAL,
        deletable: false,
        color: '#3e5e75',
        name: '',
        organization: this.organization,
        storageType: StorageType.COPY,
        agreement: false,
        mustBeExtradited: true,
        canBeExtradited: true,
        canBeExtraditedByAllBusinesses: false,
        accessType: AccessType.OPEN_AFTER_DEATH,
        privacyType: PrivacyType.SHOWN_AFTER_DEATH,
      } as KeepsakeType;
    },
    abort() {
      this.$emit('abort');
    },
    toggleNewKeepsakeType(value: boolean) {
      this.initNewKeepsakeType();
      this.creatingNew = value;
    },
    selectKeepsakeType(keepsakeType: KeepsakeType) {
      this.selected = keepsakeType;
    },
    async getKeepsakeTypes(organizationId) {

      this.loading = true;

      try {
        let keepsakeTypesRes = await get(ENDPOINTS.ORGANIZATIONS + '/' + organizationId + '/' + ENDPOINTS.KEEPSAKE_TYPES, { includeUnselectable: true })
        if (keepsakeTypesRes) {
          this.keepsakeTypes = keepsakeTypesRes;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async updateKeepsakeType(keepsakeType: KeepsakeType) {

      this.$refs.selectedForm.validate();

      if (this.selectedFormValid) {

        this.loading = true;

        try {

          let updateRes = await put(ENDPOINTS.KEEPSAKE_TYPES + '/' + keepsakeType.id, keepsakeType);

          if (updateRes) {
            let snackbar: Snackbar = {
              active: true,
              color: 'success',
              text: 'Sparade ändringar för ' + keepsakeType.name,
              timeout: 8000,
              action: null,
            };
            this.$store.commit('setSnackbar', snackbar);
            this.newDialog = false;
          }
        } catch (e) {
          console.log(e);
          let snackbar: Snackbar = {
            active: true,
            color: 'error',
            text: 'Misslyckades med att uppdatera bevakningstypen: ' + e.data.msg,
            timeout: 10000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);
        } finally {
          this.loading = false;
        }
      }
    },
    async addKeepsakeType() {

      this.$refs.newForm.validate();

      if (this.newFormValid) {
        this.loading = true;
        try {
          let keepsakeTypeResponse = await post(ENDPOINTS.KEEPSAKE_TYPES, this.newKeepsakeType);

          if (keepsakeTypeResponse) {

            this.keepsakeTypes.push(keepsakeTypeResponse);
            this.creatingNew = false;
            let snackbar: Snackbar = {
              active: true,
              color: 'success',
              text: 'Bevakningstyp tillagd!',
              timeout: 8000,
              action: null,
            };
            this.$store.commit('setSnackbar', snackbar);
            this.toggleNewKeepsakeType(false);
          }
        } catch (err) {
          console.log(err);
          let snackbar: Snackbar = {
            active: true,
            color: 'error',
            text: 'Bevakningstypen kunde inte läggas till: ' + err.data.msg,
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);

        } finally {
          this.loading = false;
        }
      }
    },
    confirmDisableKeepsakeType(keepsakeType: KeepsakeType) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: 'Inaktivera bevakningstyp',
        title: 'Bekräfta inaktivering',
        text: 'Är du säker på att du vill inaktivera ' + this.selected.name + '? ' +
          'Bevakningstypen kommer att finnas kvar för gamla bevakningar, men kan ej längre väljas för nya',
        actionClick: () => {
          this.disableKeepsakeType(keepsakeType);
        },
        closeBtnText: 'Stäng',
      };
      this.$store.commit('setDialog', dialog);
    },
    async disableKeepsakeType(keepsakeType: KeepsakeType) {

      this.loading = true;

      try {
        let disabled = await deleteRequest(ENDPOINTS.KEEPSAKE_TYPES + '/' + keepsakeType.id);

        if (disabled) {

          let index = this.keepsakeTypes.findIndex((v: KeepsakeType) => v.id == keepsakeType.id);
          if (index !== -1) {
            this.keepsakeTypes.splice(index, 1);
            this.selected = null;

            let snackbar: Snackbar = {
              active: true,
              color: 'success',
              text: 'Inaktiverade bevakningstypen',
              timeout: 6000,
              action: null,
            };
            this.$store.commit('setSnackbar', snackbar);
          }
        }
      } catch (e) {
        console.log(e);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Misslyckades med att inaktivera bevakningstypen: ' + e.data.msg,
          timeout: 10000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      } finally {
        this.loading = false;
      }
    },
  },
});

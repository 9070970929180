import dayjs from 'dayjs';

export default {
    calendarDate(val) {
        return dayjs(val).format('YYYY-MM-DD');
    },
    calendarDateTime(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm');
    },
    relativeDate(val) {
        return dayjs(val).fromNow();
  }
}

import {FileData} from "@/interfaces/fileData";

export interface AgreementSignature {
    id: number
    createdAt: string
    type: SignatureType
    fileData: FileData | null
}

export enum SignatureType {
    EMAILED = "EMAILED",
    UPLOADED = "UPLOADED",
    BANK_ID = "BANK_ID",
    PASSWORD = "PASSWORD"
}

export const SignatureTypeIcon = {
    EMAILED: "mdi-email-outline",
    UPLOADED: "mdi-cloud-upload-outline",
    BANK_ID: "mdi-cellphone-lock",
    PASSWORD: "mdi-counter"
}

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import dayjs from 'dayjs';
import localeSv from 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale(localeSv);
dayjs.extend(relativeTime);
Vue.prototype.$dayjs = dayjs;

Vue.use(Vuetify);



export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#3e5e75',
                secondary: '#6f6f6f',
                accent: '#f49b25',
                active: '#f5f9fc'
            },
        },
    },
});










































import Vue from "vue";
import NavGroupItemComponent from "@/components/navbar/NavGroupItemComponent.vue";

export default Vue.extend({
  name: 'NavGroupItemComponent',
  components: {NavGroupItemComponent},
  props: {
    subitems: Array,
    item: Object,
    subGroup: Boolean
  },
  data() {
    return {};
  },
  computed: {}
});

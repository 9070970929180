
















































import Vue from 'vue';
import { Keepsake } from '@/interfaces/keepsake/keepsake';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import DepotKeepsakesListItemComponent from '@/components/depot/details/keepsake/DepotKeepsakesListItemComponent.vue';
import { Depot, Status } from '@/interfaces/depot/depot';
import { Business } from '@/interfaces/business/business';
import { statusIsDeceased } from '@/helpers/statusHelper';

export default Vue.extend({
  components: {DepotKeepsakesListItemComponent},
  props: {
    keepsakes: Array as () => Array<Keepsake>,
    business: Object as () => Business,
    depot: Object as () => Depot,
    selected: Array as () => Array<Number> | null,
    isDeceased: Boolean,
    readOnly: Boolean,
    receipt: Boolean
  },
  data() {
    return {}
  },
  computed: {
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    sortedKeepsakes(): Array<Keepsake> {
      const keepsakesCopy: Array<Keepsake> = [...this.keepsakes];

      return keepsakesCopy.sort((a, b) => {
        // First, compare by sortOrder, treating 0 as the highest priority
        const sortOrderDifference = a.keepsakeType.sortOrder - b.keepsakeType.sortOrder;
        if (sortOrderDifference !== 0) {
          return sortOrderDifference;
        }

        // If sortOrder is the same, then sort by agreement
        if (a.keepsakeType.agreement !== b.keepsakeType.agreement) {
          return (a.keepsakeType.agreement ? -1 : 1);
        }

        // Finally, if agreement is also the same, sort by createdAt in descending order
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
    },
    selectedKeepsakes: {
      get(): Array<Number> {
        return this.selected
      },
      set(value: Array<Number>) {
        this.$emit('update:selected', value)
      }
    },
    statusIsDeceased(): boolean {
      const status: Status = this.depot.status;
      return statusIsDeceased(status);
    },
  },
  methods: {
    removeKeepsake(id: number) {
      this.$emit("keepsake-deleted", true);
    },
    handleExtradition() {
      this.$emit("handle-extradition", true);
    },
  }
});

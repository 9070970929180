





















































































































































































































































import ToolbarComponent from '@/components/ToolbarComponent.vue';
import Vue from 'vue';
import {BASE_URL, deleteRequest, ENDPOINTS, get, LOGIN_PAGE_URL, put} from '@/api/mainApi';
import {UserSession} from '@/interfaces/useraccount/userSession';
import {UserAccount} from '@/interfaces/useraccount/userAccount';
import {Snackbar} from '@/interfaces/presentation/snackbar';
import {Dialog} from '@/interfaces/presentation/dialog';
import Avatar from 'vue-avatar-component';
import NavGroupItemComponent from '@/components/navbar/NavGroupItemComponent.vue';
import {Role, RoleStr} from '@/enums/role';
import CredentialsConfirmDialog from '@/components/CredentialsConfirmDialog.vue';
import {Office} from '@/interfaces/office/office';
import OfficeSelectDialogComponent from '@/components/office/OfficeSelectDialogComponent.vue';
import TermsOfServiceDialogComponent from '@/components/tos/TermsOfServiceDialogComponent.vue';
import UserAccountSelectDialogComponent from "@/components/useraccount/UserAccountSelectDialogComponent.vue";
import {pidPattern} from "@/validators";
import validator from "validator";
import {RequestResponse} from "@/interfaces/requestResponse";
import {isHigherLevelAdmin} from "@/helpers/userAccountHelper";
import matches = validator.matches;
import PersonalDataAgreementComponent from "@/components/agreement/PersonalDataAgreementComponent.vue";

export default Vue.extend({
  components: {
    TermsOfServiceDialogComponent,
    ToolbarComponent,
    Avatar,
    NavGroupItemComponent,
    CredentialsConfirmDialog,
    OfficeSelectDialogComponent,
    UserAccountSelectDialogComponent,
    PersonalDataAgreementComponent
  },
  filters: {
    roleStr(role, roleStr) {
      return roleStr[role];
    },
  },
  data() {
    return {
      hasMultipleUserAccounts: false,
      loading: false,
      officeSelectionDialog: false,
      userAccountSelectionDialog: false as boolean,
      drawer: this.$vuetify.breakpoint.lgAndUp,
      roleStr: RoleStr,
      userOffices: [] as Array<Office>,
      userAccounts: [] as Array<UserAccount>,
      agreement: null as any
    };
  },
  computed: {
    selectedUserAccountId(): boolean {
      return this.$store.state.selectedUserAccountId;
    },
    selectedOfficeId(): number | null {
      return this.$store.state.selectedOfficeId;
    },
    sbfSupportMail(): string {
      return "info@begravningar.se";
    },
    sbfSupportPhoneNumber(): string {
      return "08 - 556 811 80"
    },
    hasMultipleOffices(): boolean {
      return this.userOffices && this.userOffices.length > 1;
    },
    usesBankId(): boolean {
      let soc = this.user.loginCredentials.socialSecurityNumber
      return soc != null && matches(soc, pidPattern)
    },
    userSession(): UserSession | null {
      return this.$store.state.userSession;
    },
    user(): UserAccount | null {
      if (this.$store.state.userSession) {
        return this.$store.state.userSession.userAccount;
      }
      return null
    },
    userAvatar(): String {
      if (this.user.fileData && this.user.fileData.src) {
        return BASE_URL + this.user.fileData.src;
      }
      return '';
    },
    snackbar(): Snackbar {
      return this.$store.state.snackbar;
    },
    dialog(): Dialog {
      return this.$store.state.dialog;
    },
    enableTosCheck(): boolean {
      return this.user && this.user.business && this.user.role === Role.BUSINESS_ADMIN;
    },
    navItems() {
      let adminNavItems: any[] = [];
      if (this.user.role !== Role.BUSINESS_USER) {
        adminNavItems = [
          {
            title: 'Administration',
            icon: 'mdi-cog-outline',
            class: 'admin_nav',
            to: {name: 'administration'},
          },
        ] as any;
        if (this.user.role === Role.SYSTEM_ADMIN) {
          adminNavItems.push({
            title: 'Globala systeminställningar',
            icon: 'mdi-earth',
            class: 'global_settings_nav',
            to: {name: 'globalsettings'},
          });
        }
      } else {
        adminNavItems = [
          {
            title: 'Mitt konto',
            icon: 'mdi-account-settings-outline',
            class: 'my_account_nav',
            to: {name: 'myAccount'},
          },
        ] as any;
      }
      let navItems = [
        {title: 'Hem', icon: 'mdi-home', class: 'home_nav', to: {name: 'home'}},
        {
          title: 'Ny bevakning',
          icon: 'mdi-clipboard-plus-outline',
          class: 'new_keepsake_nav',
          to: {name: 'newKeepsake'},
        },
      ];

      if (isHigherLevelAdmin(this.user)) {
        adminNavItems.push({
          title: 'Statistik',
          icon: 'mdi-chart-bell-curve-cumulative',
          class: 'statistics_nav',
          to: {name: 'statistics'},
        });
      }

      navItems = navItems.concat(adminNavItems as any);
      return navItems;
    },
  },
  async mounted() {
    let bankIdSessionParam = this.getSessionParam();

    if (!bankIdSessionParam) {
      this.loading = true;

      let userSession: UserSession = await this.getUserSession();

      if (userSession) {
        // make sure to logout the user and clear the session if the server requests this (e.g. after an activation)
        if (this.getClearParam()) {
          await this.logoutUser();
        }
        this.setSessionInStore(userSession);
      }
    }
    await this.getUserAccounts();

    const selectedAccount = this.userAccounts.find((u: UserAccount) => u.id === this.selectedUserAccountId);

    if (selectedAccount) {
      await this.setUserAccount(selectedAccount);
    }

    if (this.hasMultipleUserAccounts && !selectedAccount) {
      this.userAccountSelectionDialog = true;
    } else {
      await this.handleUserOffices();
    }

    if(this.$route.query.signedAgreement === 'true'){
      await this.$refs.pdataagreement.handleSignedAgreement(this.$route.query.signatureId);
    }

    this.loading = false;
  },
  methods: {
    switchOffice() {
      this.setSelectedOfficeIdInLocalStorage(null);
      window.location.reload();
    },
    switchUser() {
      this.$refs.userAccountSelectDialog.setCurrentSelectedUser();
      this.userAccountSelectionDialog = true
    },
    async getUserAccounts() {
      if (this.user.loginCredentials && this.user.loginCredentials.socialSecurityNumber) {
        const userAccounts = await this.getConnectedUserAccounts();
        this.userAccounts = userAccounts;
        if (userAccounts.length > 1) {
          this.hasMultipleUserAccounts = true;
        }
      }
    },
    userChanged(user1: UserAccount, user2: UserAccount): boolean {
      return user1 && user2 && user1.id !== user2.id;
    },
    async setUserAccount(userAccount: UserAccount) {
      if (this.userChanged(this.user, userAccount)) {
        await this.setNewUserCookie(userAccount.id);

        const userSession = await this.getUserSession();
        await this.setSessionInStore(userSession);
      }
      this.setSelectedUserAccountId(this.user.id);
      await this.handleUserOffices();
    },
    async setNewUserCookie(userId: number): Promise<RequestResponse | null> {
      return await put(
        ENDPOINTS.AUTH
        + '/' + ENDPOINTS.USER_ACCOUNTS
        + "/" + userId
        + '/' + ENDPOINTS.SESSIONS
        + '/' + ENDPOINTS.SWITCH, {});
    },
    async handleUserOffices() {
      let offices = await this.getOffices();

      this.userOffices = offices;
      const currentOffice = this.userOffices.find((o: Office) => o.id === this.selectedOfficeId);

      //if exists - use office in local storage.
      if (currentOffice) {
        this.setUserOffice(currentOffice);
      } else if (offices.length > 1) {
        this.officeSelectionDialog = true;
      } else if (offices.length == 1) {
        this.setUserOffice(offices[0]);
      }
    },
    async getOffices(): Promise<RequestResponse | null> {
      return await get(ENDPOINTS.BUSINESSES + '/' + this.user.business.id + '/' + ENDPOINTS.OFFICES);
    },
    async getConnectedUserAccounts(): Promise<RequestResponse | null> {
      return await get(ENDPOINTS.USER_ACCOUNTS
        + '/' + ENDPOINTS.CURRENT
        + '/' + ENDPOINTS.CONNECTED
        + '/' + this.user.loginCredentials.socialSecurityNumber);
    },
    async getUserSession() {
      return await get(ENDPOINTS.SESSIONS_COOKIE_USER);
    },
    setSessionInStore(userSession: UserSession) {
      this.$store.commit('updateUserSession', userSession);
    },
    setSelectedOfficeIdInStore(officeId: number | null) {
      this.$store.commit('updateSelectedOfficeId', officeId);
    },
    setSelectedUserAccountId(selectedUserAccountId: number) {
      localStorage.setItem('selectedUserAccountId', JSON.stringify(selectedUserAccountId));
    },
    setSelectedOfficeIdInLocalStorage(officeId: number | null) {
      localStorage.setItem('selectedOfficeId', JSON.stringify(officeId));
      this.setSelectedOfficeIdInStore(officeId);
    },
    async logoutUser() {
      await deleteRequest(ENDPOINTS.AUTH);
      location.href = LOGIN_PAGE_URL;
    },
    closeSnackbar() {
      this.$store.commit('setSnackbar', {active: false});
    },
    closeDialog() {
      this.$store.commit('setDialog', {active: false});
    },
    dialogActionClick() {
      this.dialog.actionClick();
      this.closeDialog();
    },
    dialogCloseClick() {
      // closeClick is optional
      if (this.dialog.closeClick) {
        this.dialog.closeClick();
      }
      this.closeDialog();
    },
    setUserOffice(office: Office) {
      let session: UserSession = this.$store.state.userSession;
      session.userAccount.office = office;

      //save current choice of office
      this.setSelectedOfficeIdInLocalStorage(office.id);

      this.setSessionInStore(session);

      if (this.user.role === Role.BUSINESS_ADMIN) {
        this.$refs.pdataagreement.handleDataProcessingAgreement();
      }
    },
    getClearParam(): String | null {
      const queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      if (urlParams.has('clearSession')) {
        return urlParams.get('clearSession');
      }
      return null;
    },
    getSessionParam(): String | null {
      const queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      if (urlParams.has('sessionId')) {
        return urlParams.get('sessionId');
      }
      return null;
    },
  },
});























import Vue from "vue";

export default Vue.extend({
  props: {
    value: String,
    label: String,
    icon: String,
    rules: Array,
    allowVisible: {
      type: Boolean,
      default: true
    },
    solo: Boolean,
    error: Boolean || null,
    errorMessages: Array || null,
  },
  data: () => ({
    passwordVisible: false,
  }),
  computed: {
    password: {
      get() {
        return this.value
      },
      set(val: string) {
        this.$emit('update:value', val)
      }
    },
    inputType() {
      return this.passwordVisible ? 'text' : 'password'
    },
    inputIcon() {
      return this.passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
    },
  },
});

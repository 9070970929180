





























































































































































































import Vue from 'vue';
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Office} from "@/interfaces/office/office";
import {deleteRequest, ENDPOINTS, post, put} from "@/api/mainApi";
import {Business} from "@/interfaces/business/business";
import {ExternalAgent} from "@/interfaces/externalagent/externalAgent";
import {Dialog} from "@/interfaces/presentation/dialog";
import {OrganizationSettings} from "@/interfaces/organization/organizationSettings";

export default Vue.extend({
    components: {},
    props: {
        selectedBusiness: Object as () => Office,
        value: Array as () => Array<Office>
    },
    data() {
        return {
            selected: null as Office | null,
            activeTab: 0,
            loading: false,
            newOffice: null as Office | null,
            creatingNew: false,
            emailRules: [
                v => !v || /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
            ],
            requiredRules: [
                v => !!v || 'Obligatoriskt fält'
            ],
            newFormValid: false,
            selectedFormValid: false,
        }
    },
    computed: {
        offices: {
            get(): Array<Office> {
                return this.value
            },
            set(value: Array<Office>) {
                this.$emit('update:value', value)
            }
        },
        business() {
            return this.selectedBusiness
        },
        organizationSettings(): OrganizationSettings {
          return this.$store.state.organizationSettings
        }
    },
    watch: {
        creatingNew(val) {
            this.activeTab = val ? 1 : 0
        },
        selectedBusiness(val) {
          if (val) {
            this.selected = null;
          }
        }
    },
    methods: {
        initNewOffice() {
            this.newOffice = {
                phone: "",
                name: "",
                email: "",
                business: this.business,
                address: null,
                sbfNumber: "",
            }
        },
        abort() {
            this.$emit('abort')
        },
        toggleNewOffice(value: boolean) {
            this.initNewOffice()
            this.creatingNew = value
        },
        selectOffice(office: Office) {
            this.selected = office
        },
        addOffice() {
            this.$refs.newForm.validate()

            if (this.newFormValid) {

              this.loading = true;

              post(ENDPOINTS.OFFICES, this.newOffice)
                .then((office: Office) => {
                  this.offices.push(office);

                  let snackbar: Snackbar = {
                    active: true,
                    color: "success",
                    text: "La till " + this.newOffice.name,
                    timeout: 8000,
                    action: null
                  }
                  this.$store.commit('setSnackbar', snackbar);
                  this.newOffice = {
                    phone: "",
                    name: "",
                    email: "",
                    business: this.business,
                    address: null,
                    sbfNumber: "",
                  }
                  this.$store.commit('setSnackbar', snackbar);
                  this.toggleNewOffice(false);
                  this.creatingNew = false;

                  if (this.organizationSettings && this.organizationSettings.autoCreateOfficeStoragePlace)
                  {
                    this.$emit("auto-added-storage-place", office)
                  }
                }).catch(err => {
                console.log(err.data.msg);
                let snackbar: Snackbar = {
                  active: true,
                  color: "error",
                  text: "Misslyckades med att lägga till kontor: " + err.data.msg,
                  timeout: 10000,
                  action: null
                }
                this.$store.commit('setSnackbar', snackbar)
              }).finally(() => {
                this.loading = false;
                this.creatingNew = false
              })
            }
        },
        updateOffice(office: Office) {
          this.$refs.selectedForm.validate();

          if (this.selectedFormValid) {
            this.loading = true;

            put(ENDPOINTS.OFFICES + "/" + office.id, office)
              .then((office: Office) => {
                let snackbar: Snackbar = {
                  active: true,
                  color: "success",
                  text: "Sparade ändringar för " + office.name,
                  timeout: 8000,
                  action: null
                };
                this.$store.commit('setSnackbar', snackbar);
              }).catch(err => {
              console.log(err.data.msg);
              let snackbar: Snackbar = {
                active: true,
                color: "error",
                text: "Misslyckades med att uppdatera kontor: " + err.data.msg,
                timeout: 10000,
                action: null
              }
              this.$store.commit('setSnackbar', snackbar)
            }).finally(() => {
              this.loading = false;
              this.newDialog = false
            })
          }
        },
        confirmDeactivateOffice(office: Office) {
          let dialog: Dialog = {
            active: true,
            actionBtnText: "Inaktivera kontor",
            title: "Bekräfta radering",
            text: "Är du säker på att du vill inaktivera " + office.name + "?",
            actionClick: () => {
              this.deactivateOffice(office)
            },
            closeBtnText: "Stäng"
          }
          this.$store.commit('setDialog', dialog)
        },
      async deactivateOffice(office: Office) {

        this.loading = true;

        try {
          let deactivated = await deleteRequest(ENDPOINTS.OFFICES + "/" + office.id);

          if (deactivated) {

            let index = this.offices.findIndex((v: Office) => v.id == office.id)
            if (index !== -1) {
              this.offices.splice(index, 1);
              let snackbar: Snackbar = {
                active: true,
                color: "primary",
                text: "Inaktiverade " + office.name,
                timeout: 8000,
                action: null
              }
              this.$store.commit('setSnackbar', snackbar);
              this.selected = null;
            }
          }
        } catch (err) {
          console.log(err);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att inaktivera kontoret: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        } finally {
          this.loading = false;
        }
      },
    }
})


























































































































































































































































import Vue from 'vue';
import {deleteRequest, ENDPOINTS, get, post, put} from '@/api/mainApi';
import {UserSession} from '@/interfaces/useraccount/userSession';
import {SystemFunction} from '@/interfaces/system/systemFunction';
import {Dialog} from '@/interfaces/presentation/dialog';
import {Snackbar} from '@/interfaces/presentation/snackbar';
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {isSysAdmin} from "@/helpers/userAccountHelper";
import { PersonalDataProcessingAgreementRepository } from '@/repositories/personalDataProcessingAgreementRepository';
import { SystemRepository } from '@/repositories/systemRepository';

export default Vue.extend({
  components: {},
  data() {
    return {
      deleteAllTestData: true,
      inputRules: {
        commaSeparated: value => /[0-9]+(,[0-9]+)*/.test(value) || 'Felaktigt formatterat',
        onlyNumbers: value => /^[0-9,]+$/.test(value) || 'Endast siffror och kommatecken är tillåtet',
      },
      depotIds: null,
      dialog: false,
      loading: false,
      systemFunctions: [] as SystemFunction[],
      systemRepository: new SystemRepository(this),
      maxDepotsValue: 1000, // Default value, for how many depots we shall try to monitor at once. Should be a reasonable value to see how the system handles the burden
    };
  },
  computed: {
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    userSession(): UserSession {
      return this.$store.state.userSession;
    },
    isSysAdmin(): boolean {
      return isSysAdmin(this.user);
    },
  },
  mounted() {
    this.getSystemFunctions();
  },
  methods: {
    confirmPerformFunction(functionTitle: string, functionDescription: string, actionClick: Function) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: functionTitle,
        title: 'Är du säker?',
        text: functionDescription,
        actionClick: actionClick,
        closeBtnText: 'Avbryt',
      };
      this.$store.commit('setDialog', dialog);
    },
    confirmDeleteTestData() {
      if (this.$refs.depotIdsForm.validate()) {
        const description = this.depotIds == null || this.depotIds == '' ?
          'Är du säker på att du vill radera all testdata? alla depåer kommer att raderas.'
          :
          'Är du säker på att du vill radera depåer med följande id: ' + this.depotIds + ' ?';

        this.confirmPerformFunction('Radera testdata', description, this.deleteTestData);
      }
    },
    confirmMassUserActivation() {
      this.confirmPerformFunction('Skicka ut aktiveringsmejl',
        "Bekräfta att du vill att samtliga användarkonton som ännu inte aktiverats ska få ett mejl med länk till aktiveringssidan utskickat",
        this.sendMassActivationEmails);
    },
    confirmUpdateUnmonitoredDepots() {
      this.confirmPerformFunction('Uppdatera monitorering',
        "Bekräfta att du vill uppdatera icke-monitorerade depåer",
        this.updateUnmonitoredDepots);
    },
    confirmUpdatePendingDepots() {
      this.confirmPerformFunction('Uppdatera väntande depåer',
        "Bekräfta att du vill uppdatera väntande monitorerade depåer",
        this.updatePendingDepots);
    },
    confirmUpdateTrackedDepots() {
      this.confirmPerformFunction('Uppdatera spårade depåer',
        "Bekräfta att du vill uppdatera spårade monitorerade depåer",
        this.updateTrackedDepots);
    },
    deleteTestData() {
      this.dialog = false;
      this.loading = true;
      deleteRequest(ENDPOINTS.TEST_DATA, {ids: this.depotIds})
        .then(response => {
          let snackbar: Snackbar = {
            active: true,
            color: 'success',
            text: response,
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);

        }).catch(err => {
        console.error(err);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Misslyckades med att radera testdata: ' + err.data,
          timeout: 10000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      }).finally(() => {
        this.loading = false;
      });
    },
    sendMassActivationEmails() {
      this.loading = true;
      post(ENDPOINTS.USER_ACCOUNTS + "/" + ENDPOINTS.INVITE)
        .then(response => {
          let snackbar: Snackbar = {
            active: true,
            color: 'success',
            text: response.msg,
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);

        }).catch(err => {
        console.error(err);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Misslyckades med att skicka aktiveringsmejl: ' + err.data,
          timeout: 10000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      }).finally(() => {
        this.loading = false;
      });
    },
    async updateUnmonitoredDepots() {
      this.loading = true;
      await this.systemRepository.updateUnmonitoredDepots(this.maxDepotsValue);
      this.loading = false;
    },
    async updatePendingDepots() {
      this.loading = true;
      await this.systemRepository.updatePendingDepots(this.maxDepotsValue);
      this.loading = false;
    },
    async updateTrackedDepots() {
      this.loading = true;
      await this.systemRepository.updateTrackedDepots();
      this.loading = false;
    },
    async getSystemFunctions() {
      this.loading = true;
      this.systemFunctions = await get(ENDPOINTS.SYSTEM + '/' + ENDPOINTS.FUNCTIONS, {});
      this.loading = false;
    },
    changeSystemFunction(systemFunction: SystemFunction) {
      put(ENDPOINTS.SYSTEM + '/' + ENDPOINTS.FUNCTIONS + '/' + systemFunction.id, systemFunction);
    },
  },
});

export enum AccessType {
    OPEN = "OPEN",
    OPEN_AFTER_DEATH = "OPEN_AFTER_DEATH",
    CLOSED = "CLOSED",
}

export enum AccessTypeStr {
    OPEN = "Alltid öppen",
    OPEN_AFTER_DEATH = "Öppen efter dödsfall",
    CLOSED = "Stängd",
}


export const AccessTypeArr = [
    {value: AccessType.OPEN, text: AccessTypeStr.OPEN},
    {value: AccessType.OPEN_AFTER_DEATH, text: AccessTypeStr.OPEN_AFTER_DEATH},
    {value: AccessType.CLOSED, text: AccessTypeStr.CLOSED},
]

























































































































































import Vue from "vue";
import PasswordInputComponent from "@/components/form/PasswordInputComponent.vue";
import {LoginCredentials} from "@/interfaces/useraccount/loginCredentials";
import Axios from "axios";
import {BASE_URL, ENDPOINTS, getService, HEADERS, post} from "@/api/mainApi";
import BankIdInfoComponent from "@/components/BankIdInfoComponent.vue";

export default Vue.extend({
  components: {BankIdInfoComponent, PasswordInputComponent},
  data: () => ({
    loginCredentials: {
      username: "",
      password: "",
      socialSecurityNumber: ""
    } as LoginCredentials,
    loginMethod: 0,
    loginMethods: {
      BANK_ID: 0,
      USERNAME_PASSWORD: 1,
    },
    bankIdDialog: false,
    validForm: false,
    loading: false,
    errorMsg: "",
    emailRules: [
      v => !!v || 'Du måste ange en e-postadress',
      v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
    ],
    requiredRules: [
      v => !!v || 'Obligatoriskt fält'
    ]
  }),
  computed: {},
  async mounted() {

    let userSessionParam = this.getSessionParam();

    if (userSessionParam) {
      let res = await this.authBankIdSession(userSessionParam)
    }

    // backend call to check if user is authorized
    // custom API call here to handle unauthorized
    try
    {
        let response = await Axios.get(BASE_URL + "/api/" + ENDPOINTS.AUTH,
            {
                headers: {
                    [HEADERS.ContentType]: [HEADERS.ApplicationJson]
                },
                withCredentials: true
            })
        let authResponse = response.data
        if (authResponse)
        {
            location.href = BASE_URL
        }
    }
    catch (error)
    {
        // Treat network errors without responses as 500s.
        const status = error.response ? error.response.status : 500
        if (status === 401)
        { // Unauthorised
        }
        else
        {
            this.errorMsg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
        }
    }
  },
  methods: {
    async doLogin() {
      this.$refs.form.validate()
      if (this.validForm) {
        this.errorMsg = ""
        if (this.loginMethod === this.loginMethods.BANK_ID) {
          this.initiateBankidLogin()
        } else {
          this.initiatePasswordLogin()
        }
      }
    },
    async initiateBankidLogin() {
      this.bankIdDialog = true;
      try {
        let redirectResponse = await getService(ENDPOINTS.GRANDID + "/" + ENDPOINTS.SIGN, {login: true})
        if (redirectResponse)
        {
          location.href = redirectResponse.redirectUrl;
        }
      } catch (error) {
        console.error(error);
        if (error.status === 400) {
          this.errorMsg = "Felaktigt personnummer"
        } else if (error.status === 409) {
          this.errorMsg = "Det finns en annan användare med samma personnummer. Vänligen kontakta SBF omgående"
        } else {
          this.errorMsg = "Kunde inte öppna upp BankID. Något gick fel: " + error.data.msg
        }
      } finally {
        this.bankIdDialog = false;
      }
    },
    async initiatePasswordLogin() {
      this.loading = true
      // backend call to authorize user
      // custom API call here to handle unauthorized when email or password is wrong
      try {
        let response = await Axios.post(BASE_URL + "/api/" + ENDPOINTS.AUTH, this.loginCredentials,
          {
            withCredentials: true
          })
        let authResponse = response.data
        if (authResponse) {
          this.$store.commit('updateUser', authResponse)
          location.href = "/"
        }
      } catch (error) {
        // Treat network errors without responses as 500s.
        const status = error.response ? error.response.status : 500
        if (status === 401) { // Unauthorised
          this.errorMsg = "Felaktigt användarnamn eller lösenord. Vänligen kontrollera dina inloggningsuppgifter"
          this.$refs.username.focus()
        } else {
          this.errorMsg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
        }
      }
      finally {
        this.loading = false
      }
    },
    getSessionParam(): String | null {
      const queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      if (urlParams.has("sessionId")) {
        return urlParams.get("sessionId")
      }
      return null
    },
    async authBankIdSession(sessionId: string) {
      try {
        let loginResponse = await post(ENDPOINTS.AUTH + "/" + ENDPOINTS.BANK_ID + "?sessionId=" + sessionId,  {})

        let authResponse = loginResponse.data
        if (authResponse) {
          this.$store.commit('updateUser', authResponse);
        }
      } catch (error) {
        console.error(error);
        if (error.status === 409) {
          this.errorMsg = "Det finns en annan användare med samma personnummer. Vänligen kontakta SBF omgående"
        } else {
          this.errorMsg = "Kunde inte logga in med BankID. Något gick fel: " + error.data.msg
        }
      }

      return false;
    }
  }
})

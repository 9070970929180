import {BusinessSettings} from '@/interfaces/business/businessSettings';
import {deleteRequest, ENDPOINTS, get, post, put} from '@/api/mainApi';
import Vue from 'vue';
import {Snackbar} from '@/interfaces/presentation/snackbar';
import {AxiosError} from 'axios';
import {Business} from "@/interfaces/business/business";

export class BusinessSettingsRepository {
    constructor(private vueInstance: Vue) {
    }

    public async getAllBusinessSettings(): Promise<Array<BusinessSettings>> {
        try {
            return await get(`${ENDPOINTS.BUSINESSES}/${ENDPOINTS.SETTINGS}`);
        } catch (error) {
            this.handleError(error, `Misslyckades med att hämta alla företagsinställningar: ${error.data.msg || 'Okänt fel'}`);
            return Promise.reject(error);
        }
    }

    public async getBusinessSettingsById(id: number): Promise<BusinessSettings> {
        try {
            return await get(`${ENDPOINTS.BUSINESSES}/${ENDPOINTS.SETTINGS}/${id}`);
        } catch (error) {
            this.handleError(error, `Misslyckades med att hämta företagsinställningar: ${error.data.msg || 'Okänt fel'}`);
            return Promise.reject(error);
        }
    }

    public async getBusinessSettingsByBusinessId(businessId: number): Promise<Array<BusinessSettings>> {
        try {
            return await get(`${ENDPOINTS.BUSINESSES}/${businessId}/${ENDPOINTS.SETTINGS}`);
        } catch (error) {
            this.handleError(error, `Misslyckades med att hämta företagsinställningar: ${error.data.msg || 'Okänt fel'}`);
            return Promise.reject(error);
        }
    }

    public async createBusinessSettings(businessSettings: BusinessSettings): Promise<BusinessSettings> {
        try {
            const response = await post(`${ENDPOINTS.BUSINESSES}/${ENDPOINTS.SETTINGS}`, businessSettings);
            this.setSnackbar('Företagsinställningar skapade', 'success');
            return response;
        } catch (error) {
            this.handleError(error, `Misslyckades med att skapa företagsinställningar: ${error.data.msg || 'Okänt fel'}`);
            return Promise.reject(error);
        }
    }

    public async deleteBusinessSettings(id: number): Promise<BusinessSettings> {
        try {
            const response = await deleteRequest(`${ENDPOINTS.BUSINESSES}/${ENDPOINTS.SETTINGS}/${id}`);
            this.setSnackbar('Företagsinställningar borttagna', 'success');
            return response;
        } catch (error) {
            this.handleError(error, `Misslyckades med att ta bort företagsinställningar: ${error.response?.data.msg || 'Okänt fel'}`)
            return Promise.reject(error);
        }
    };

    private setSnackbar(message: string, color: 'error' | 'success') {
        const snackbar: Snackbar = {
            active: true,
            color: color,
            text: message,
            timeout: 10000,
            action: null,
        };
        this.vueInstance.$store.commit('setSnackbar', snackbar);
    }

    public async moveBusinessInformation(newBusinessId: number, oldBusinessId: number): Promise<Business> {
        try {
            const response = await put(`${ENDPOINTS.BUSINESSES}/${newBusinessId}/${ENDPOINTS.MOVE}`, {id: oldBusinessId});
            this.setSnackbar('Flytt av företagets innehåll slutförd', 'success');
            return response;
        } catch (error) {
            this.handleError(error, `Misslyckades med att flytta företagets innehåll: ${error.data.msg || 'Okänt fel'}`);
            return Promise.reject(error);
        }
    }

    private handleError(error: AxiosError, errorMessage: string) {
        if (error.response?.status === 403) {
            this.setSnackbar('Du saknar rättigheter för att utföra den begärda handlingen', 'error');
        } else {
            this.setSnackbar(errorMessage, 'error');
        }
    }
}

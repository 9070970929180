export function parseQuery(query: any) {
    const parts = query.replace('?', '').split('&');
    const params = parts.reduce((acc: any, str: any) => {
        const [key, value] = str.split('=');
        acc[key] = value;
        return acc;
    }, {});
    return params;
}

export function removeQueryParams(...params: any[]) {
    const query = new URLSearchParams(window.location.search.replace('?', ''));
    params.forEach(param => query.delete(param));
    history.pushState(null, '', window.location.pathname + query);
}

// Helper functions during development
export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function extractDomainNameFromUrl(url: string) {
    var matches = url.match(/^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i);
    return matches && matches[1];  // domain will be null if no match is found
}

export function getParamValue(identifier: string): string | null {
  const queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  if (urlParams.has(identifier)) {
    return urlParams.get(identifier)
  }
  return null
}

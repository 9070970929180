import { Status } from '@/interfaces/depot/depot';

export function statusIsDeceased(status: Status): boolean {
  if (!status) {
    return false;
  }
  return status === Status.MARKED_AS_DECEASED_BY_USER ||
         status === Status.MARKED_AS_DECEASED_BY_SPAR ||
         status === Status.DECEASED_WORK_IN_PROGRESS ||
         status === Status.DECEASED_PROTECTED_IDENTITY ||
         status === Status.DECEASED_WORK_IN_PROGRESS_PROTECTED_IDENTITY
}

export function statusIsSPAR(status: Status): boolean {
  if (!status) {
    return false;
  }
  return status === Status.MATCHED_WITH_SPAR ||
         status === Status.DELISTED_WITH_SPAR ||
         status === Status.MARKED_AS_DECEASED_BY_SPAR
}




























































import Vue from 'vue';
import {Status, StatusArr, StatusStr} from '@/interfaces/depot/depot';
import {isHigherLevelAdmin} from "@/helpers/userAccountHelper";
import {UserAccount} from "@/interfaces/useraccount/userAccount";

export default Vue.extend({
  props: {
    multiple: Boolean,
    label: String,
    value: [Object as () => Status, Array as () => Array<Status>],
  },
  data() {
    return {
      selectables: null,
      statusStr: StatusStr,
      defaultStatuses: [] as Array<Status>,
      savedFiltersActive: false,
      panel: 0
    };
  },
  computed: {
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    selected: {
      get(): Array<Status> | Status {
        return this.value;
      },
      set(value: Array<Status> | Status) {
        this.$emit('update:value', value);
      },
    },
    filteredStartPageDepotStatuses(): Status[] | null {
      return this.$store.state.filteredStartPageDepotStatuses;
    },
  },
  mounted() {
    this.defaultStatuses = this.value;
    this.setSelectables();
    if (this.filteredStartPageDepotStatuses && this.filteredStartPageDepotStatuses.length > 0) {
      this.savedFiltersActive = true;
      this.selected = this.filteredStartPageDepotStatuses;
    }
  },
  filters: {
    statusStr(status, statusStr) {
      return statusStr[status];
    },
  },
  methods: {
    setSelectables() {
      if (isHigherLevelAdmin(this.user)) {
        this.selectables = StatusArr;
      } else {
        this.selectables = StatusArr.filter(s => s != Status.DECEASED_PROTECTED_IDENTITY) as Array<Status>;
      }
    },
    identicalArrays(arr1: Array<Status>, arr2: Array<Status>): boolean {
      if (arr1.length !== arr2.length) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
      return true;
    },
    changedSelected(value: Array<Status>) {
      if (!!value && value.length > 0 && !this.identicalArrays(value, this.defaultStatuses)) {
        this.savedFiltersActive = true;
        this.$store.commit('populateFilteredStartPageDepotStatuses', value);
      }
      // the user reverted to the default statuses
      if (this.identicalArrays(value, this.defaultStatuses)) {
        this.clearFilteredStatuses();
      }
    },
    clearFilteredStatuses() {
      this.selected = this.defaultStatuses;
      this.savedFiltersActive = false;
      this.$store.commit('clearFilteredStartPageDepotStatuses');
    }
  },
});

import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {AgreementSignature} from "@/interfaces/depot/agreementSignature";
import { Business } from '@/interfaces/business/business';

export interface Agreement {
    id: number;
    createdAt: string;
    createdBy: UserAccount;
    depotId: number;
    type: AgreementType;
    agreementSignature: AgreementSignature | null;
    business: Business;
    archived: boolean;
    info: string | null;
    parentId: number | null;
    variant: AgreementVariant | null;
}

export enum AgreementType {
    DEPOT_AGREEMENT = "DEPOT_AGREEMENT",
    KEEPSAKE_EXTRADITION_AGREEMENT = "KEEPSAKE_EXTRADITION_AGREEMENT",
    KEEPSAKE_SUBMISSION_AGREEMENT = "KEEPSAKE_SUBMISSION_AGREEMENT",
}

export enum AgreementTypeValue {
  DEPOT_AGREEMENT = 1,
  KEEPSAKE_EXTRADITION_AGREEMENT = 2,
  KEEPSAKE_SUBMISSION_AGREEMENT = 3,
}

export const AgreementTypeStr = {
    DEPOT_AGREEMENT: "Depåavtal",
    KEEPSAKE_EXTRADITION_AGREEMENT: "Utlämningsavtal",
    KEEPSAKE_SUBMISSION_AGREEMENT: "Inlämningsavtal",
}
export const AgreementTypeIcon = {
    DEPOT_AGREEMENT: "mdi-file-cabinet",
    KEEPSAKE_EXTRADITION_AGREEMENT: "mdi-exit-to-app",
    KEEPSAKE_SUBMISSION_AGREEMENT: "mdi-location-enter",
}

export enum AgreementVariant {
  LIVING = "LIVING",
  DECEASED = "DECEASED",
}

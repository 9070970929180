import Vue from 'vue'
import Vuex from 'vuex'
import {UserSession} from "@/interfaces/useraccount/userSession";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Dialog} from "@/interfaces/presentation/dialog";
import {OrganizationSettings} from "@/interfaces/organization/organizationSettings";
import {SigningInProgressState} from "@/interfaces/bankid/signingInProgressState";
import { Status } from '@/interfaces/depot/depot';

Vue.use(Vuex)

class State {
    userSession: UserSession | null = null;
    selectedUserAccountId: number | null = null;
    selectedOfficeId: number | null = null;
    signingInProgress: SigningInProgressState | null = null;
    organizationSettings: OrganizationSettings | null = null;
    filteredStartPageDepotStatuses: Status[] | null = null;
    snackbar: Snackbar | null = null;
    dialog: Dialog = {
        active: false,
        title: "",
        text: "",
        closeBtnText: "",
        actionBtnText: "",
        actionClick: () => {
        },
        closeClick: () => {
        }
    };
    credentialsConfirmDialog: Dialog = {
        active: false,
        title: "",
        text: "",
        actionClick: () => {
        },
        closeClick: () => {
        }
    };
}

export default new Vuex.Store({
    state: new State(),
    mutations: {
        updateUserSession(state, session: UserSession) {
            state.userSession = session
        },
        updateOrganizationSettings(state, organizationSettings: OrganizationSettings) {
            state.organizationSettings = organizationSettings
        },
        updateSelectedOfficeId(state, selectedOfficeId: number | null) {
            state.selectedOfficeId = selectedOfficeId
        },
        setSnackbar(state: State, snackbar: Snackbar) {
            // Default values
            let active = typeof snackbar.active !== "undefined" ? snackbar.active : true
            let color = snackbar.color ? snackbar.color : "primary"
            let timeout = snackbar.timeout ? snackbar.timeout : 6000 // 6 sec
            let text = snackbar.text ? snackbar.text : "Viktigt meddelande"

            // hide snackbar incase it is already showing
            if (state.snackbar?.active) {
                state.snackbar.active = false
            }
            // then we set the snackbar with proper values after a small delay
            window.setTimeout(() => {
                state.snackbar = {
                    active: active,
                    color: color,
                    timeout: timeout,
                    text: text,
                    action: snackbar.action
                }
            }, 100)
        },
        setDialog(state, dialog) // dialog is used instead of confirmation alerts (e.g when confirming to delete an item)
        {
            // Default values
            let active = typeof dialog.active !== "undefined" ? dialog.active : true
            if (state.dialog?.active) {
                dialog.active = active
            }
            // hide dialog incase it is already showing (should not happen)
            state.dialog.active = false

            // then we set the dialog with proper values after a small delay
            window.setTimeout(() => {
                state.dialog = dialog
            }, 100)
        },
        setCredentialsConfirmDialog(state, dialog) // Special dialog where user has to confirm by inputing password
        {
            // Default values
            let active = typeof dialog.active !== "undefined" ? dialog.active : true
            if (state.credentialsConfirmDialog?.active) {
                dialog.active = active
            }
            // hide dialog incase it is already showing (should not happen)
            state.credentialsConfirmDialog.active = false

            // then we set the dialog with proper values after a small delay
            window.setTimeout(() => {
                state.credentialsConfirmDialog = dialog
            }, 100)
        },
        setSigningInProgress(state, signingInProgressData: SigningInProgressState) {
          localStorage.setItem('signingInProgress', JSON.stringify(signingInProgressData));
          state.signingInProgress = signingInProgressData;
        },
        removeSigningInProgress(state) {
          localStorage.removeItem('signingInProgress');
          state.signingInProgress = null;
        },
        populateFilteredStartPageDepotStatuses(state, statuses: Status[]) {
          if (statuses && statuses.length > 0) {
            localStorage.setItem('filteredStartPageDepotStatuses', JSON.stringify(statuses));
            state.filteredStartPageDepotStatuses = statuses;
          }
        },
        clearFilteredStartPageDepotStatuses(state) {
          localStorage.removeItem('filteredStartPageDepotStatuses');
          state.filteredStartPageDepotStatuses = null;
        },
        initialiseStore(state) {
          let selectedUserAccountId = localStorage.getItem('selectedUserAccountId')
          if (selectedUserAccountId) {
            state.selectedUserAccountId = JSON.parse(selectedUserAccountId);
          }

          let selectedOfficeId = localStorage.getItem('selectedOfficeId')
          if (selectedOfficeId) {
            state.selectedOfficeId = JSON.parse(selectedOfficeId);
          }

          let signingVal = localStorage.getItem('signingInProgress')
          if (signingVal) {
            state.signingInProgress = JSON.parse(signingVal);
          }

          let filteredStartPageDepotStatuses = localStorage.getItem('filteredStartPageDepotStatuses')
          if (filteredStartPageDepotStatuses) {
            state.filteredStartPageDepotStatuses = JSON.parse(filteredStartPageDepotStatuses);
          }
      },
    },
    actions: {}
})

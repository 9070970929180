

























import Vue from 'vue';
import { ENDPOINTS, get, post, put } from '@/api/mainApi';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { Keepsake } from '@/interfaces/keepsake/keepsake';
import { DepotKeepsake } from '@/interfaces/keepsake/depotKeepsake';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { EventLogTypeValue } from '@/interfaces/eventlog/eventLogType';
import { EntityTypeValue } from '@/enums/entityType';

export default Vue.extend({
  components: { },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    keepsake: {
      type: Object as () => Keepsake,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false as Boolean,
      createdBy: null as UserAccount | null,
    };
  },
  computed: {
    createdAt() {
      return this.note.createdAt;
    },
    show: {
      get: function(): boolean {
        return this.value;
      },
      set: function(newValue: boolean) {
        this.$emit('update:value', newValue);
      },
    },
    note: {
      get(): Keepsake {
        return this.keepsake
      },
      set(val: Keepsake) {
        this.$emit('update:keepsake', val)
      }
    }
  },
  watch: {
    value(value) {
      this.show = value;
    },
    show(value) {
      if (value) {
        this.logNoteAsRead();
      }
    },
    'keepsake': {
      handler(val: Keepsake) {
        if (val && val.id) {
          this.getCreatedBy();
        }
      },
      immediate: true
    },
  },
  methods: {
    saveNote() {
      if (this.note && !this.readonly) {
        this.loading = true;
        put(ENDPOINTS.KEEPSAKES + "/" + this.note.id, this.note).then(note => {
          this.loading = false;
          let snackbar: Snackbar = {
            active: true,
            color: "success",
            text: "Sparade anteckningen",
            timeout: 3000,
            action: null
          };

          this.$store.commit('setSnackbar', snackbar);

          setTimeout(() => {
            this.$emit("save", note);
            this.show = false;
          }, 500)

        }).catch(err => {
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Kunde inte spara anteckning. Vänligen försök igen",
            timeout: 5000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
          console.log(err)
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    async getCreatedBy() {
      if (this.keepsake && this.keepsake.depotKeepsakes) {
        let depotKeepsake: DepotKeepsake = this.keepsake.depotKeepsakes.find(x => x.createdBy > 0);
        if (depotKeepsake && depotKeepsake.createdBy) {
          try {
            this.createdBy = await get(ENDPOINTS.USER_ACCOUNTS + "/" + depotKeepsake.createdBy)
          } catch (e) {
            console.error(e)
          }
        }
      }
    },
    async logNoteAsRead() {
      try {
        await post(ENDPOINTS.EVENT_LOGS, {
          eventLogTypeValue: EventLogTypeValue.KEEPSAKE_NOTE_READ_BY_USER,
          entityTypeValue: EntityTypeValue.KEEPSAKE,
          entityId: this.note.id
        })
      } catch (e) {
        console.error(e)
      }
    }
  },
});

import { BASE_URL, ENDPOINTS, get, post } from '@/api/mainApi';
import Vue from 'vue';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { AxiosError } from 'axios';
import { BusinessSettings } from '@/interfaces/business/businessSettings';
import { PersonalDataProcessingAgreementType } from '@/enums/personalDataAgreementType';

export class PersonalDataProcessingAgreementRepository {
  constructor(private vueInstance: Vue) {
  }

  public async getIsPersonalDataProcessingAgreementsSignedByType(type: PersonalDataProcessingAgreementType): Promise<boolean> {
    try {
      return await get(`${ENDPOINTS.PERSONAL_DATA_AGREEMENTS}/${type}/${ENDPOINTS.SIGNED}`);
    } catch (error) {
      this.handleError(error, `Misslyckades med att hämta information personuppgiftsbiträdesavtalet: ${error.data.msg || 'Okänt fel'}`);
      return Promise.reject(error);
    }
  }

  public getPersonalDataAgreementUrlByType(type: PersonalDataProcessingAgreementType): String {
    return `${BASE_URL}/api/${ENDPOINTS.PERSONAL_DATA_AGREEMENTS}/${type}`
  }

  public async signProcessingAgreementsByType(type: PersonalDataProcessingAgreementType, signatureParam: string | null): Promise<BusinessSettings> {
    try {
      const response = await post(`${ENDPOINTS.PERSONAL_DATA_AGREEMENTS}/${type}/${ENDPOINTS.SIGN}${signatureParam ? signatureParam : ''}`, {});
      this.setSnackbar('Personuppgiftsbiträdesavtalet signerat', 'success');
      return response;
    } catch (error) {
      this.handleError(error, `Misslyckades med att signera personuppgiftsbiträdesavtalet: ${error.data.msg || 'Okänt fel'}`);
      return Promise.reject(error);
    }
  }

  private setSnackbar(message: string, color: 'error' | 'success') {
    const snackbar: Snackbar = {
      active: true,
      color: color,
      text: message,
      timeout: 10000,
      action: null,
    };
    this.vueInstance.$store.commit('setSnackbar', snackbar);
  }

  private handleError(error: AxiosError, errorMessage: string) {
    if (error.response?.status === 403) {
      this.setSnackbar('Du saknar rättigheter för att utföra den begärda handlingen', 'error');
    } else {
      this.setSnackbar(errorMessage, 'error');
    }
  }
}

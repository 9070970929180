export enum Role {
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  BUSINESS_ADMIN = 'BUSINESS_ADMIN',
  BUSINESS_USER = 'BUSINESS_USER',
}

export enum RoleStr {
  SYSTEM_ADMIN = 'Systemadministratör',
  ORGANIZATION_ADMIN = 'Organisationsadministratör',
  BUSINESS_ADMIN = 'Företagsadministratör',
  BUSINESS_USER = 'Användare',
}

export interface RoleInterface {
  text: string,
  value: string
}

export const RoleArray = [
  {
    text: RoleStr.SYSTEM_ADMIN,
    value: Role.SYSTEM_ADMIN,
  },
  {
    text: RoleStr.ORGANIZATION_ADMIN,
    value: Role.ORGANIZATION_ADMIN,
  },
  {
    text: RoleStr.BUSINESS_ADMIN,
    value: Role.BUSINESS_ADMIN,
  },
  {
    text: RoleStr.BUSINESS_USER,
    value: Role.BUSINESS_USER,
  },
];

export const adminRoles = [
  Role.SYSTEM_ADMIN,
  Role.BUSINESS_ADMIN,
  Role.ORGANIZATION_ADMIN,
];

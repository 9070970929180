

























import Vue from 'vue';
import { TosStatus } from '@/interfaces/tos/tosStatus';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { ENDPOINTS, get, post } from '@/api/mainApi';

export default Vue.extend({
  components: {},
  data() {
    return {
      active: false,
      loading: false,
      tosStatus: null as TosStatus | null,
    };
  },
  computed: {
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
  },
  async mounted() {
    const status = await this.getTosStatus()
    this.tosStatus = status
    if(status && !status.tosAgreed)
    {
      this.active = true
    }
  },
  methods: {
    async getTosStatus():Promise<TosStatus | null> {
      return await get(ENDPOINTS.TOS_STATUS);
    },
    async agreeToTos() {
      this.loading = true
      await post(ENDPOINTS.TOS + "/" + this.tosStatus.tosText.id)
      this.loading = false
      this.closeDialog();
    },
    closeDialog() {
      this.active = false;
    },
  },
});

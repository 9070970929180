
























import Vue from "vue";
import validators from "@/validators";
import {Depot} from "@/interfaces/depot/depot";
import {Dialog} from "@/interfaces/presentation/dialog";

export default Vue.extend({
    props: {
        value: String,
        label: String,
        icon: String,
        hint: String,
        solo: Boolean,
        error: Boolean,
        errorMessages: Array,
        disabled: Boolean,
        grayText: Boolean,
        readonly: Boolean,
        persistentHint: Boolean,
        clearable: Boolean,
        identifier: String,
        clearInfo: String,
        required: {
          type: Boolean,
          default: true
        },
    },
    data: () => ({
        rules: {},
        ssnMask: '########-####',
        oldSsn: null
    }),
    computed: {
        ssn: {
            get()
            {
                return this.value
            },
            set(val: string)
            {
                this.$emit('update:value', val)
            }
        },
    },
    created() {
        Object.assign(this.rules, validators());
    },
    methods: {
        clearSsnField() {
          this.$emit("clear-ssn", this.ssn)
          this.oldSsn = "";
        },
        cancelClear() {
          this.ssn = this.oldSsn
          this.oldSsn = ""
        },
        confirmClearSsnField() {
          this.oldSsn = this.ssn;
          let dialog: Dialog = {
            active: true,
            actionBtnText: "Rensa",
            title:"Bekräfta rensning",
            text: "Är du säker på att du vill rensa fältet " + (this.identifier ? " för " + this.identifier : "") + "?" +
              (this.clearInfo ? " " + this.clearInfo : ""),
            actionClick: () => {
              this.clearSsnField()
            },
            closeClick: () => {
              this.cancelClear();
            },
            closeBtnText: "Avbryt"
          }

          this.$store.commit('setDialog', dialog);
        }
  }
});



























































import Vue from 'vue';
import { EventLog } from '@/interfaces/eventlog/eventLog';
import { EventLogTypeStr } from '@/interfaces/eventlog/eventLogType';
import { BASE_URL } from '@/api/mainApi';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import Avatar from 'vue-avatar-component';

export default Vue.extend({
  components: {
    Avatar,
  },
  data() {
    return {
      eventLogTypeStr: EventLogTypeStr,
    };
  },
  props: {
    eventLogs: Array as () => Array<EventLog>,
  },
  computed: {
    items(): Array<EventLog> { // we use computed here just to get some intellisense from interface
      return this.eventLogs;
    },
  },
  filters: {
    logTypeName(eventLog, eventLogType: EventLogTypeStr) {
      return eventLogType[eventLog];
    },
  },
  methods: {
    getUserAvatar(user: UserAccount): String {
      if (user.fileData && user.fileData.src) {
        return BASE_URL + user.fileData.src;
      }
      return '';
    },
  },
});















































































































































import Vue from 'vue';
import {Keepsake} from '@/interfaces/keepsake/keepsake';
import {UserAccount} from '@/interfaces/useraccount/userAccount';
import {AccessType} from '@/interfaces/keepsake/enums/accessType';
import {PrivacyType} from '@/interfaces/keepsake/enums/privacyType';
import {Office} from '@/interfaces/office/office';
import {BASE_URL, deleteRequest, ENDPOINTS, MainApi} from '@/api/mainApi';
import {Snackbar} from '@/interfaces/presentation/snackbar';
import KeepsakeFileUploadMenuComponent from '@/components/depot/details/keepsake/KeepsakeFileUploadMenuComponent.vue';
import PlaceTransferDialogComponent from '@/components/place/PlaceTransferDialogComponent.vue';
import {Dialog} from '@/interfaces/presentation/dialog';
import {DepotKeepsake} from '@/interfaces/keepsake/depotKeepsake';
import {SigningInProgressState} from '@/interfaces/bankid/signingInProgressState';
import {BankidSignatureType} from '@/interfaces/bankid/bankidSignatureType';
import {pidPattern} from '@/validators';
import validator from 'validator';
import {getParamValue} from '@/utils/generalUtil';
import NoteComponent from '@/components/NoteComponent.vue';
import {AttachedFileType} from '@/interfaces/keepsake/enums/attachedFileType';
import { isBusinessAdmin, isHigherLevelAdmin, isOrgAdmin, isSysAdmin } from '@/helpers/userAccountHelper';
import {Business} from "@/interfaces/business/business";
import {LifeArchiveRepository} from '@/repositories/lifeArchiveRepository';

import matches = validator.matches;
import KeepsakeExtraditionComponent from '@/components/depot/details/keepsake/KeepsakeExtraditionComponent.vue';
import { hasOngoingSignatureAction } from '@/helpers/signatureHelper';

export default Vue.extend({
  components: {
    KeepsakeExtraditionComponent,
    NoteComponent, KeepsakeFileUploadMenuComponent, PlaceTransferDialogComponent},
  props: {
    business: Object as () => Business,
    keepsake: Object as () => Keepsake,
    office: Object as () => Office,
    isDeceased: Boolean,
    depotId: Number,
    readOnly: Boolean,
    receipt: Boolean
  },
  data() {
    return {
      fileLoading: false,
      moveKeepsakesDialog: false,
      showExtraditionDialog: false,
      showNote: false,
      lifeArchiveRepository: new LifeArchiveRepository(this),
      signingSourceId: "649b49c2-a5f5-4071-a114-645a108f8064"
    }
  },
  computed: {
    userBusiness(): Business {
      return this.$store.state.userSession.userAccount.business;
    },
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    isDigitalLifeArchive(): boolean {
      return this.keepsake.keepsakeType.digitalLifeArchive;
    },
    item: {
      get(): Keepsake {
        return this.keepsake
      },
      set(val: Keepsake) {
        this.$emit('update:keepsake', val)
      },
    },
    keepsakeOffice() {
      // if office is provided use it otherwise use office from the item
      return this.office ? this.office : this.item.office
    },
    isKeepsakeEnabled(): boolean { // Here we check if a keepsake is accessible
      if (isOrgAdmin(this.stateUser)) {
        return this.hasOrgAdminAccessToOrg;
      } else if (isSysAdmin(this.stateUser)) {
        return true;
      } else if (this.stateUser.business && this.stateUser.business.id != this.keepsakeOffice.business.id) {
        if (this.isDeceased && this.item.keepsakeType.accessType === AccessType.OPEN_AFTER_DEATH || this.item.keepsakeType.accessType === AccessType.OPEN) {
          return !this.readOnly
        }
        // if previous if statement is false then AccessType is CLOSED which means keepsake should always be disabled
        return false
      }
      return !this.readOnly
    },
    isFileUploadAccessible(): boolean {
      if (!this.item.attachedFile || !!this.item.fileData) {
        return false;
      }

      // org admin and sysadmin can always upload files, since they should be able to assist with any issues
      if ((isOrgAdmin(this.stateUser) || isSysAdmin(this.stateUser))) {
        return true;
      }
      // The keepsake must have at least one connection to an office from the same business as the user
      return this.keepsake.depotKeepsakes.some((depotKeepsake: DepotKeepsake) => depotKeepsake.office.business.id === this.userBusiness.id)
    },
    /**
     * Extradition directly from the keepsake is only available if the keepsake can be extradited by other businesses (and it is indeed another business)
     */
    canKeepsakeBeExtraditedByOtherBusiness(): boolean {
      return this.item.keepsakeType.canBeExtradited && this.item.keepsakeType.canBeExtraditedByAllBusinesses
        && !this.hasBeenExtradited && this.userBusiness.id !== this.keepsakeOffice.business.id;
    },
    /**
     * Checks if admin user is connected to given business.
     * */
    hasOrgAdminAccessToOrg(): boolean {
      return this.userBusiness.organization.id === this.business.organization.id;
    },
    canViewKeepsake(): boolean { // Here we check if the keepsake details should be shown
      if (isOrgAdmin(this.stateUser)) {
        return this.hasOrgAdminAccessToOrg;
      } else if (isSysAdmin(this.stateUser)) {
        return true;
      } else if (this.stateUser.business && this.stateUser.business.id != this.keepsakeOffice.business.id) {
        if (this.isDeceased && this.item.keepsakeType.privacyType === PrivacyType.SHOWN_AFTER_DEATH || this.item.keepsakeType.privacyType === PrivacyType.ALWAYS_SHOWN) {
          return true
        }
        // if previous if statement is false then PrivacyType is NEVER_SHOWN which means keepsake should always be private
        return false
      }
      return true
    },
    isUserBusinessActive(): boolean {
      if (isOrgAdmin(this.stateUser)) {
        return this.hasOrgAdminAccessToOrg;
      } else if (isSysAdmin(this.stateUser)) {
        return true;
      }
      return this.stateUser && this.office.business && this.stateUser.business.id === this.office.business.id;
    },
    sharedDepot(): DepotKeepsake | null {
      if (this.item.depotKeepsakes && this.item.depotKeepsakes.length > 1) {
        return this.item.depotKeepsakes.find((depotKeepsake: DepotKeepsake) => depotKeepsake.depot.id !== this.depotId)
      }
      return null
    },
    hasBeenExtraditedForOther(): boolean {
      if (this.item.depotKeepsakes && this.item.depotKeepsakes.length > 1) {
        let otherKeepsakes: DepotKeepsake[] = this.item.depotKeepsakes.filter(dk => !(dk.depot.id === this.depotId))
        return otherKeepsakes.some((depotKeepsake) => depotKeepsake.extradition)
      }
      return false
    },
    hasBeenExtradited(): boolean {
      if (this.item.depotKeepsakes) {
        let ownKeepsakes: DepotKeepsake[] = this.item.depotKeepsakes.filter(dk => dk.depot.id === this.depotId)
        return ownKeepsakes.some((depotKeepsake) => depotKeepsake.extradition)
      }
      return false
    },
    keepsakeCanHaveFileButMissing(): boolean {
      const keepsake: Keepsake = this.item;
      return keepsake.keepsakeType.attachedFileType !== AttachedFileType.NONE && !keepsake.fileData
    },
    usesBankId(): boolean {
      let soc = this.stateUser.loginCredentials.socialSecurityNumber
      return soc != null && matches(soc, pidPattern)
    },
    ongoingDeletionSigningInProgress(): boolean {
      return hasOngoingSignatureAction(this.signingInProgress, BankidSignatureType.KEEPSAKE_DELETION, this.signingSourceId);
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress
    },
    hasDisplayableNote(): boolean {
      return this.canViewKeepsake && this.item.text && this.item.text.length > 0
    }
  },
  mounted() {
    if (this.ongoingDeletionSigningInProgress && this.signingInProgress.keepsakeIds) {
      let id: number = Number.parseInt(this.signingInProgress.keepsakeIds);
      if (id === this.item.id) {
        this.deleteKeepsake(this.getBankIdSignatureIdParam());
      }
    }
  },
  methods: {
    showNoteItem(item: Keepsake) {
      this.showNote = !this.showNote;
    },
    viewAttachedFile() {
      this.fileLoading = true
      try {
        window.open(BASE_URL + "/" + MainApi + "/" + ENDPOINTS.KEEPSAKES + "/" + this.item.id + "/" + ENDPOINTS.ATTACHED_FILE + "?filename=" + this.item.fileData.filename, "_blank")
      } catch (e) {
        console.error(e)
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Något gick fel när filen skulle öppnas. Kontakta support om problemet kvarstår",
          timeout: 8000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      }
      this.fileLoading = false
    },
    viewDigitalLifeArchive() {
      this.fileLoading = true
      try {
        let lifeArchiveUrl = this.lifeArchiveRepository.getLifeArchiveDocumentUrl(this.depotId);
        window.open(lifeArchiveUrl, "_blank");
      } catch (e) {
        console.error(e)
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Något gick fel när livarkivsfilen skulle öppnas. Kontakta support om problemet kvarstår",
          timeout: 8000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      }
      this.fileLoading = false
    },
    canDeleteKeepsake(keepsake: Keepsake): boolean {
      const isHighLevelAdminOrBusinessAdmin: boolean = isHigherLevelAdmin(this.stateUser) || isBusinessAdmin(this.stateUser);
      return keepsake.keepsakeType.deletable && !this.ongoingDeletionSigningInProgress && isHighLevelAdminOrBusinessAdmin
    },
    confirmKeepsakeDeletion() {
      this.active = false;
      if (this.usesBankId) {
        // this delays the actual extradition since we shall navigate away
        this.setDeletionSigningStatusInStore()
      }

      let dialog: Dialog = {
        active: true,
        actionClick: () => this.deleteKeepsake(),
        title: "Bekräfta radering",
        text: "Är du helt säker på att du vill radera den valda bevakningen?" + "\n\n" +
          (this.sharedDepot ? "Bevakningen är delad, så den kommer även att raderas för  " + this.sharedDepot.depot.name + "\n\n" : "") +
          "Detta är en permanent ändring och bevakningen kommer omedelbart att raderas" + "\n\n" +
          "Informationen kan ej återställas",
      };
      this.$store.commit('setCredentialsConfirmDialog', dialog);
    },
    removeSigning() {
      this.$store.commit('removeSigningInProgress')
    },
    deleteKeepsake(bankidSignatureId: string) {

      let bankIdSignatureParam = ""
      if (bankidSignatureId) {
        bankIdSignatureParam += "?signatureId=" + bankidSignatureId;
      }

      deleteRequest(ENDPOINTS.KEEPSAKES + "/" + this.item.id + bankIdSignatureParam)
        .then((res) => {
          let snackbar: Snackbar = {
            active: true,
            color: "success",
            text: "Raderade bevakning",
            timeout: 8000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
          this.$emit('keepsake-deleted', this.keepsake.id); // todo - this might not work
        }).catch(err => {
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Kunde ej radera bevakning: " + err,
          timeout: 8000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      }).finally(() => {
        // delete any possible signings just to be safe
        this.removeSigning();
        this.$router.push(this.$route.path);
      })
    },
    handleKeepsakeTransfer(event) {
      this.keepsake.storagePlace = event.toPlace;
    },
    setDeletionSigningStatusInStore() {

      let keepsakeIdText: string = this.item.id.toString();

      // prepare the signing by adding data for it, but it is not yet ready
      let signingInProgressState: SigningInProgressState = {
        bankidSignatureType: BankidSignatureType.KEEPSAKE_DELETION,
        depotId: this.depotId,
        keepsakeIds: keepsakeIdText,
        sourceId: this.signingSourceId,
        ready: false
      }

      this.$store.commit('setSigningInProgress', signingInProgressState)
    },
    extraditeKeepsake() {
      this.showExtraditionDialog = true;
    },
    handleExtradition() {
      this.$emit('handle-extradition', true);
    },
    getBankIdSignatureIdParam(): string | null {

      let bankIdSignatureId = getParamValue("signatureId");

      if (!bankIdSignatureId) {
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Kunde inte hitta BankID-signaturen inför radering",
          timeout: 30000,
          action: null
        };

        this.$store.commit('setSnackbar', snackbar);
      }
      return bankIdSignatureId;
    },
  }
});



































import Vue from 'vue';
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {RoleArray} from "@/enums/role";
import {ENDPOINTS, put} from "@/api/mainApi";
import {Organization} from "@/interfaces/organization/organization";
import {OrganizationSettings} from "@/interfaces/organization/organizationSettings";

export default Vue.extend({
  components: {},
  props: {
    value: Object as () => Organization
  },
  data() {
    return {
      loading: false,
      regFormValid: false,
    }
  },
  computed: {
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    organizationSettings(): OrganizationSettings {
      return this.$store.state.organizationSettings
    }
  },
  methods: {
    async updateOrganizationSettings() {

      let valid = this.$refs.regForm.validate()

      if (valid) {

        this.loading = true;

        try {
          let updateResponse = await put(ENDPOINTS.ORGANIZATION_SETTINGS + "/" + this.organizationSettings.id, this.organizationSettings);

          if (updateResponse) {
            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Sparade organisationsinställningarna",
              timeout: 6000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
          }
        } catch (err) {
          console.log(err);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att spara ändringarna: " + err.data.msg,
            timeout: 10000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        } finally {
          this.loading = false;
        }
      }
    },
  },
})
